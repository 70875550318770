import React, {
    Fragment, useState, useEffect,
    useRef, createRef
} from "react";

import MUIDataTable from "mui-datatables";

import { getMOMsInstances } from "../../apis/analysis";
import { Grid, Paper, Hidden } from "@material-ui/core";
import moment from "moment";

function formatTimestamp(ts) {
    return moment(ts).format("D MMMM YYYY, HH:mm");
}

function buildName(name) {
    const { first_name, last_name } = name;
    return `${first_name} ${last_name}`;
}

function processMomsReportData(moms) {
    const data = moms.map(report => {
        const {
            observance_ts, narrative: n_details, reporter, validator, op_trigger, remarks
        } = report;
        const { narrative, timestamp: report_ts } = n_details;
        return [
            formatTimestamp(observance_ts), narrative, formatTimestamp(report_ts),
            buildName(reporter), buildName(validator), remarks, op_trigger
        ];
    });

    return data;
}

const moms_features_data = [
    [
        "Crack",
        "F",
        "Near School",
        "17 July 2022, 11:30",
        0,
        12
    ]
]

const moms_reports = [
    [
        "17 July 2022, 11:30",
        "Entry entered to close Mx trigger->M0; assumed timestamp of observance",
        "17 July 2022, 11:30",
        "Dynaslope Senslope",
        "Kevin Dhale Dela Cruz",
        "Entry entered to close Mx trigger->M0; assumed timestamp of observance",
        0
    ],
    [
        "17 July 2022, 07:30",
        "new crack found near LAYSB",
        "17 July 2022, 07:30",
        "Dynaslope Senslope",
        "Rodney Estrada",
        "New landslide feature observed in the crown and toe area of the site which indicate active and significant ground movement",
        2
    ]
]


function LandslideFeaturesTable(props) {
    const [moms_features, setMOMsFeatures] = useState([]);
    const [moms_data, setMOMsData] = useState([]);
    const [open_reports, setOpenReports] = useState(false)

    const columns = ["Type",
        {
            name: "Name",
            options: {
                filter: false
            }
        }, {
            name: "Location",
            options: {
                filter: false
            }
        }, {
            name: "Last Observance Timestamp",
            options: {
                filter: false
            }
        }, "Alert Level", {
            name: "data",
            options: {
                display: false,
                viewColumns: false,
                filter: false
            }
        }
    ];

    const report_columns = ["Observance Timestamp", "Narrative", "Report Timestamp", "Reporter", "Validator", "Remarks", "Alert Level"]
    useEffect(() => {
        getMOMsInstances("lpa", data => {
            const tbl_data = data.map(instance => {
                const {
                    feature: { feature_type },
                    feature_name, moms, instance_id,
                    location
                } = instance;

                const type = feature_type.charAt(0).toUpperCase() + feature_type.slice(1);
                const last_mom = moms[0];

                let return_data = [];
                if (typeof last_mom !== "undefined") {
                    const { observance_ts, op_trigger } = last_mom;
                    const ts = formatTimestamp(observance_ts);
                    return_data = [type, feature_name, location, ts, op_trigger, instance_id];
                }

                return return_data;
            });
            const final_tbl_data = tbl_data.filter(item => {
                return item.length > 0;
            });

            const sorted_data = final_tbl_data.sort((a, b) => b[4] - a[4]);
            setMOMsFeatures(sorted_data);
            setMOMsData(data);
        });
    }, []);

    const [is_moms_modal_open, setMomsModal] = useState(false);
    const set_moms_modal_fn = bool => () => setMomsModal(bool);
    return (
        <Fragment>
            <Paper style={{ marginTop: 30 }}>
                <MUIDataTable key="landslide_features"
                    title={`Landslide Features of LPA`}
                    columns={columns}
                    options={{
                        textLabels: {
                            body: {
                                noMatch: "No data"
                            }
                        },
                        selectableRows: "none",
                        rowsPerPage: 5,
                        rowsPerPageOptions: [],
                        print: false,
                        download: false,
                        responsive: "standard",
                        onRowClick(data, meta, e) {
                            console.log(data)
                            setOpenReports(!open_reports)
                        }
                    }}
                    data={moms_features}
                />
                {
                    open_reports && (
                        <Fragment key="landslide_data">
                            <div style={{ marginTop: 30 }}>
                                <MUIDataTable
                                    title={`Landslide reports for Crack F`}
                                    columns={report_columns}
                                    options={{
                                        textLabels: {
                                            body: {
                                                noMatch: "No data"
                                            }
                                        },
                                        selectableRows: "none",
                                        rowsPerPage: 5,
                                        rowsPerPageOptions: [],
                                        print: false,
                                        download: false,
                                        responsive: "standard",
                                        // onRowClick(data, meta, e) {
                                        //     console.log(data)
                                        //     setOpenReports(!open_reports)
                                        // }
                                    }}
                                    data={moms_reports}
                                />
                            </div>
                        </Fragment>
                    )
                }
            </Paper>
        </Fragment>
    )
}


export default LandslideFeaturesTable;