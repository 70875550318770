import React, {useState, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {
  Grid,
  Container,
  Button,
  Typography,
  FormControl,
  FormHelperText,
  InputAdornment,
  IconButton,
} from '@mui/material';
import FabMuiTable from '../utils/MuiTable';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Box from '@mui/material/Box';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {MobileDateTimePicker} from '@mui/x-date-pickers/MobileDateTimePicker';
import EditIcon from '@mui/icons-material/Edit';
// import {uploadMomsResources, getFilesFromFolder} from '../../apis/Misc';
import {
  getFeatures,
  getInstances,
  insertMomsEntry,
  getStaffs,
} from '../../apis/Moms';
import {getSitesData} from './api/opcen';
import moment from 'moment';
import PromptModal from './modals/PromptModal';
import {makeStyles} from '@material-ui/core/styles';
import MomsTable from './MomsTable';
import ListItemText from '@mui/material/ListItemText';

import Swal from 'sweetalert2';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
    },
  },
  textarea: {
    resize: 'both',
  },
}));

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 200,
      width: 250,
    },
  },
};

const Moms = props => {
  const {site_code} = useParams();
  const [site, setSite] = useState(null);

  useEffect(() => {
    if (site_code)
      getSitesData(results => {
        let tempSite = results.find(site => site.site_code === site_code);
        setSite(tempSite);
      });
  }, [site_code]);

  useEffect(() => {
    if (site) reloadTable();
  }, [site]);

  const [open, setOpen] = useState(false);

  const [datetimestamp, setDateTimestamp] = useState(new Date());
  const [selectedFeatureIndex, setSelectedFeatureIndex] = useState(null);
  // const [selectedFeatureName, setSelectedFeatureName] = useState("");
  const [selectedAlertLevel, setSelectedAlertLevel] = useState(null);
  const [narrative, setNarrative] = useState('');
  const [featureDetails, setFeatureDetails] = useState('');
  const [featureLocation, setFeatureLocation] = useState('');
  // const [reporter, setReporter] = useState({
  //   user_id: "",
  //   first_name: "",
  //   last_name: "",
  // });
  const [featureName, setFeatureName] = useState({});
  const [featureNames, setFeatureNames] = useState([
    {
      name: 'New Instance',
      instance_id: null,
    },
  ]);

  const [instances, setInstances] = useState([]);
  // const [instanceID, setInstanceID] = useState(null);
  // const [staffs, setStaffs] = useState([]);

  // const [existingFeatureName, setExistingFeatureName] = useState(false);
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    let credentials = localStorage.getItem('credentials');
    console.log('credentials', credentials);
    // setUserId(credentials.user.user_id);
  }, []);

  const feature_list = [
    {
      feature_id: 1,
      feature: 'Crack',
      details:
        'Ilang crack ang nakita?: ' +
        '\nGaano kahaba?: ' +
        '\nGaano kalapad?: ' +
        '\nAno ang lalim nito?: ' +
        '\nAno ang oryentasyon o direksyon?: ' +
        '\nGaano kalaki ang pagbabago? (Kung luma): ',
    },
    {
      feature_id: 2,
      feature: 'Scarp',
      details:
        'Ilang scarp ang nakita?: ' +
        '\nGaano kahaba?: ' +
        '\nGaano kalapad?: ' +
        '\nAno ang taas nito?: ' +
        '\nAno ang oryentasyon o direksyon?: ' +
        '\nGaano kalaki ang pagbabago?(Kung luma): ',
    },
    {
      feature_id: 3,
      feature: 'Seepage',
      details:
        'Gaano kabilis/kalakas ang daloy ng tubig?: ' +
        '\nGaano karami ang tubig na umaagos?: ' +
        '\nAno ang kulay ng tubig?: ' +
        '\nAno ang oryentasyon o direksyon ng daloy ng tubig?: ' +
        '\nMayroon bang seepage kahit wala nang pag-ulan?: ',
    },
    {
      feature_id: 4,
      feature: 'Ponding',
      details:
        'Gaano kalaki ang ponding?: ' +
        '\nGaano kalalim ang ponding?: ' +
        '\nMayroon bang kalapit na iba pang landslide feature?: ' +
        '\nBagong ponding o dati pa?: ',
    },
    {
      feature_id: 5,
      feature: 'Tilted/Split Trees',
      details:
        'Saang direksyon nakatagilid/nakatabingi/nahati ang puno?: ' +
        '\nGaano kalaking area ang naapektuhan nito?: ' +
        '\nPara sa split trees, gaano kalaki ang hati?: ',
    },
    {
      feature_id: 6,
      feature: 'Damaged Structures',
      details:
        'Mayroon bang mga paglubong sa sahig o pagtagilid ng mga dingding?: ' +
        '\nSaan nakita ang crack at ano ang oryentasyon nito?: ' +
        '\nSino ang may-ari ng istrukturang ito?: ',
    },
    {
      feature_id: 7,
      feature: 'Slope Failure',
      details:
        'Saang bahagi ng slope ito na-obserbahan?: ' +
        '\nGaano ito kataas at kalapad?: ' +
        '\nGaano kalayo ang narating ng pagguho ng lupa?: ' +
        '\nMalalim o mababaw ba ang slope failure? ' +
        '\nMayroon bang mga naapektuhang istruktura?: ',
    },
    {
      feature_id: 8,
      feature: 'Bulging/Depression',
      details:
        'Ilan ang nakitang pag-umbok o paglubog ng lupa?: ' +
        '\nGaano ito kalaki?: ' +
        '\nGaano ito kalalim o kataas?: ' +
        '\nMayroon bang kalapit na iba pang landslide feature?: ',
    },
  ];

  useEffect(() => {
    if (site) reloadFeature();
  }, [selectedFeatureIndex]);

  const reloadFeature = () => {
    setFeatureName('');

    getFeatures(site.site_code, response => {
      if (response.status) {
        let tempData = response.data;

        tempData.map(feature => {
          if (feature.feature_id == selectedFeatureIndex) {
            let tempFeatureNames = [
              {
                name: 'New Instance',
                instance_id: null,
              },
            ];

            if (feature.instances.length > 0) {
              feature.instances.map(instance => {
                tempFeatureNames.push({
                  instance_id: instance.instance_id,
                  name: instance.feature_name,
                });
              });
            }

            setFeatureNames(tempFeatureNames);
          }
        });
      }
    });

    setFeatureDetails(
      selectedFeatureIndex != null
        ? feature_list.find(o => o.feature_id == selectedFeatureIndex).details
        : '',
    );
  };

  const reloadTable = () => {
    getInstances(site.site_code, response => {
      if (response) {
        setInstances(response);
      }
    });
  };

  //   const handleUpload = uploadImage => {
  //     const formData = new FormData();
  //     formData.append('file', uploadImage);
  //     formData.append('folder', 'moms_images');

  //     uploadMomsResources(formData, data => {
  //       const {status, message} = data;
  //       if (status) {
  //         getFilesFromFolder('moms_images', response => {
  //           // setFiles(response)
  //         });
  //       } else {
  //         console.log('Error upload', message);
  //       }
  //     });
  //   };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    initialize();
    setOpen(false);
  };

  const initialize = () => {
    setDateTimestamp(new Date());
    setSelectedFeatureIndex(null);
    // setSelectedFeatureName("");
    setSelectedAlertLevel(null);
    setFeatureDetails('');
    setNarrative('');
    setFeatureLocation('');
    // setReporter({
    //   user_id: "",
    //   first_name: "",
    //   last_name: "",
    // });
    setFeatureName({});
    setFeatureNames([
      {
        name: 'New Instance',
        instance_id: null,
      },
    ]);
    setIncomplete(false);
  };

  const [incomplete, setIncomplete] = useState(false);
  const checkRequired = () => {
    if (selectedAlertLevel == null || selectedFeatureIndex == null)
      return false;
    else if (featureName.instance_id == null && featureLocation == '')
      return false;
    else if (!featureName.hasOwnProperty('instance_id')) return false;
    else if (narrative == '' || featureDetails == '') return false;
    else return true;
  };

  const submitMoms = () => {
    let moms_entry = {
      site_code: site.site_code,
      moms_list: [
        {
          alert_level: selectedAlertLevel,
          instance_id: featureName.instance_id,
          feature_name:
            featureName.instance_id == null ? null : featureName.name,
          feature_type: feature_list.find(
            o => o.feature_id == selectedFeatureIndex,
          ).feature,
          report_narrative: featureDetails,
          observance_ts: moment(new Date(datetimestamp)).format(
            'YYYY-MM-DD HH:mm:ss',
          ),
          remarks: narrative,
          reporter_id: 1, //default muna, need mag create ng general community account
          validator_id: 1,
          location: featureLocation,
          iomp: 1,
          file_name: '',
        },
      ],
      uploads: [],
    };
    console.log('moms_entry', moms_entry);

    insertMomsEntry(moms_entry, response => {
      if (response.status == true) {
        initialize();
        reloadTable();
        Swal.fire({
          icon: 'success',
          title: 'Success!',
          text: 'Landslide Feature saved!',
        });
        setOpen(false);
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error!',
          text: 'Error on saving landslide feature, please contact developers',
        });
      }
    });
  };

  const handleSubmit = () => {
    let valid = checkRequired();

    if (valid) {
      let promptMsg = `Date: ${moment(new Date(datetimestamp)).format(
        'YYYY-MM-DD HH:mm:ss',
      )}\n`;
      promptMsg += `Feature Type: ${
        feature_list.find(o => o.feature_id == selectedFeatureIndex).feature
      }\n`;
      if (featureName.instance_id != null)
        promptMsg += `Feature Name: ${featureName.name}\n`;
      promptMsg += `Description: ${featureDetails}\n`;
      promptMsg += `Narratives: ${narrative}\n`;
      promptMsg += `Alert Level: ${selectedAlertLevel}`;

      setConfirmation(true);
      // setOpen(false);
      setOpenPrompt(true);
      setErrorPrompt(false);
      setPromptTitle('Please confirm inputs: ');
      setNotifMessage(promptMsg);
    } else {
      setIncomplete(true);
    }
  };

  const [openPrompt, setOpenPrompt] = useState(false);
  const [promptTitle, setPromptTitle] = useState('');
  const [notifMessage, setNotifMessage] = useState('');
  const [errorPrompt, setErrorPrompt] = useState(false);
  const [confirmation, setConfirmation] = useState(false);

  return (
    <Grid sx={{px: 10, paddingTop: 2}}>
      <PromptModal
        isOpen={openPrompt}
        error={errorPrompt}
        title={promptTitle}
        setOpenModal={setOpenPrompt}
        notifMessage={notifMessage}
        confirmation={confirmation}
        callback={response => {
          if (response) {
            submitMoms();
          }
        }}
      />

      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        style={{zIndex: 1059}}>
        <DialogTitle>Enter new landslide feature</DialogTitle>
        <DialogContent>
          <Grid item xs={12} style={{paddingTop: 10}}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MobileDateTimePicker
                label="Date and Time"
                value={datetimestamp}
                onChange={e => {
                  setDateTimestamp(e);
                }}
                renderInput={params => (
                  <TextField
                    style={{width: '100%', paddingBottom: 10}}
                    {...params}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton edge="end">
                            <EditIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12}>
            <FormControl
              fullWidth
              style={{width: '100%', paddingBottom: 15}}
              error={incomplete && selectedFeatureIndex == null}>
              <InputLabel id="demo-simple-select-label">
                Feature Type
              </InputLabel>
              <Select
                error={incomplete && selectedFeatureIndex == null}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Feature Type"
                value={selectedFeatureIndex}
                onChange={e => {
                  setSelectedFeatureIndex(e.target.value);
                  // setSelectedFeatureName(
                  //   selectedFeatureIndex != null
                  //     ? feature_list.find(
                  //         (o) => o.feature_id == selectedFeatureIndex
                  //       ).feature
                  //     : ""
                  // );
                }}>
                {feature_list &&
                  feature_list.map((row, index) => (
                    <MenuItem key={index} value={row.feature_id}>
                      {row.feature}
                    </MenuItem>
                  ))}
              </Select>
              <FormHelperText>
                {incomplete && selectedFeatureIndex == null && 'required'}
              </FormHelperText>
            </FormControl>
            {selectedFeatureIndex != null && (
              <FormControl
                fullWidth
                style={{width: '100%', paddingBottom: 15}}
                error={
                  incomplete && !featureName.hasOwnProperty('instance_id')
                }>
                <InputLabel id="demo-simple-select-label">
                  Feature Name
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Feature Name"
                  value={featureName.name}
                  onChange={e => {
                    setFeatureName(e.target.value);
                  }}>
                  {featureNames.map((row, index) => (
                    <MenuItem key={index} value={row}>
                      {row.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>
                  {incomplete &&
                    !featureName.hasOwnProperty('instance_id') &&
                    'required'}
                </FormHelperText>
              </FormControl>
            )}
          </Grid>
          {featureName.hasOwnProperty('instance_id') &&
            featureName.instance_id == null && (
              <Grid item xs={12}>
                <TextField
                  error={incomplete && featureLocation == ''}
                  helperText={incomplete && featureLocation == '' && 'required'}
                  id="outlined-required"
                  label="Location"
                  variant="outlined"
                  style={{width: '100%', paddingBottom: 10}}
                  value={featureLocation}
                  onChange={e => {
                    setFeatureLocation(e.target.value);
                  }}
                />
              </Grid>
            )}
          <Grid item xs={12}>
            <TextField
              error={incomplete && featureDetails == ''}
              helperText={incomplete && featureDetails == '' && 'required'}
              id="outlined-required"
              label="Feature Description"
              variant="outlined"
              style={{width: '100%', paddingBottom: 10}}
              value={featureDetails}
              onChange={e => {
                setFeatureDetails(e.target.value);
              }}
              multiline
              rows={6}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              error={incomplete && narrative == ''}
              helperText={incomplete && narrative == '' && 'required'}
              id="outlined-required"
              label="Narratives"
              placeholder="According to LEWC Juan Dela Cruz, a crack appeared behind his house, with a length of 1 meter and an opening of 2 cm. It appeared around 9AM after the heavy rainfall."
              variant="outlined"
              style={{width: '100%', paddingBottom: 10}}
              value={narrative}
              onChange={e => {
                setNarrative(e.target.value);
              }}
              multiline
              rows={4}
            />
          </Grid>

          {/* <Grid item xs={12}>
            <FormControl fullWidth style={{ width: "100%", paddingBottom: 15 }}>
              <InputLabel id="demo-simple-select-label">Reporter</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Reporter"
                value={`${reporter.first_name} ${reporter.last_name}`}
                onChange={(e) => {
                  setReporter(e.target.value);
                }}
                renderValue={(selected) => selected}
                MenuProps={MenuProps}
              >
                {staffs.map((staff) => (
                  <MenuItem
                    key={staff.user_id}
                    // value={`${staff.first_name} ${staff.last_name}`}
                    value={staff}
                  >
                    <ListItemText
                      primary={`${staff.first_name} ${staff.last_name}`}
                    />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid> */}

          <FormControl
            fullWidth
            style={{width: '100%', paddingBottom: 15}}
            error={incomplete && selectedAlertLevel == null}>
            <InputLabel id="demo-simple-select-label">Alert Level</InputLabel>
            <Select
              error={incomplete && selectedAlertLevel == null}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Alert level"
              onChange={e => {
                setSelectedAlertLevel(e.target.value);
              }}>
              <MenuItem key={0} value={0}>
                Alert level 0
              </MenuItem>
              <MenuItem key={2} value={2}>
                Alert level 2
              </MenuItem>
              <MenuItem key={3} value={3}>
                Alert level 3
              </MenuItem>
            </Select>
            <FormHelperText>
              {incomplete && selectedAlertLevel == null && 'required'}
            </FormHelperText>
          </FormControl>
          {/* <FormControl fullWidth style={{width: '100%', paddingBottom: 15}}>
            <input
              accept="image/*"
              style={{display: 'none'}}
              id="raised-button-file"
              type="file"
              multiple
              onChange={e => {
                console.log(e.target.files);
                // handleUpload(e.target.files[0]);
              }}
            />

            <label htmlFor="raised-button-file" style={{textAlign: 'center'}}>
              <Button variant="contained" component="span" sx={{mx: 1}}>
                Upload MoMs Images
              </Button>
            </label>
          </FormControl> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit} variant="contained">
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      {(site_code === 'lpa' || site_code === 'scu') && (
        <Grid container spacing={4} sx={{padding: '2%'}}>
          <Grid item xs={12}>
            <Typography variant="h3" align="center" fontSize={40}>
              {site_code === 'lpa'
                ? `Brgy. Lipata`
                : `Sitio Campo Uno, Brgy. Tenani`}{' '}
              Landslide Features
            </Typography>
          </Grid>
        </Grid>
      )}

      <Grid container spacing={4} sx={{mb: 6, padding: '2%'}}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="h4">Landslide Features</Typography>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="contained"
                onClick={handleClickOpen}
                style={{
                  float: 'right',
                }}>
                Add Landslide Feature
              </Button>
            </Grid>
            <MomsTable instances={instances} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Moms;
