import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Typography,
  TextField,
  Grid,
  IconButton,
  Tooltip,
} from '@mui/material';
import React, {Fragment, useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import moment from 'moment';
import {sendMessage} from '../api/opcen';

import {CopyToClipboard} from 'react-copy-to-clipboard';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import LibraryAddCheckRoundedIcon from '@mui/icons-material/LibraryAddCheckRounded';
import { getSitesData } from '../api/opcen';

const CBEWSL_SITE_NAME = `${process.env.REACT_APP_SITE_NAME}`;
const CBEWSL_SITE_LOCATION = `${process.env.REACT_APP_SITE_LOCATION}`;

function DisseminateModal(props) {
  const {
    isOpen,
    disseminateData,
    setOpenModal,
    handleSendSMS,
    monitoringReleases,
    setMonitoringReleases,
    setTriggers,
    triggers,
    capitalizeFirstLetter,
    ewiTemplates,
  } = props;

  const navigate = useNavigate();
  const [latest_triggers, setLatestTriggers] = [];
  const [message, setMessage] = useState('');
  const [copyMessage, setCopyMessage] = useState('');
  const [copyStatus, setCopyStatus] = useState(false);
  const [alertLevel, setAlertLevel] = useState('');
  const [siteLocation, setSiteLocation] = useState('');
  const [currentAlertTs, setCurrentAlertTs] = useState('');
  const [triggerSource, setTriggerSource] = useState([]);
  const [barangayResponse, setBarangayResponse] = useState('');
  const [communityResponse, setCommunityResponse] = useState('');
  const [lewcResponse, setLewcResponse] = useState('');
  const [municipalResponse, setMunicipalResponse] = useState('');
  const [provincialResponse, setProvincialResponse] = useState('');
  const [siteCode, setSiteCode] = useState('');
  const [barangay, setBarangay] = useState('');
  const [households, setHouseholds] = useState("");
  const [latestEventTriggers, setLatestEventTriggers] = useState([]);

  useEffect(() => {
    if (siteCode) {
      getSitesData(results => {
        const site = results.find(site => site.site_code === siteCode);
        if (site) {
          setHouseholds(site.households);
  
          const { sitio, barangay, municipality, province } = site;
          let site_barangay;
          let site_location;
  
          if (sitio) {
            site_barangay = `Sitio ${sitio}, Brgy. ${barangay}`;
          } else {
            site_barangay = `Brgy. ${barangay}`;
          }
  
          setBarangay(site_barangay);

          site_location = `${site_barangay}, ${municipality}, ${province}`;
          setSiteLocation(site_location);
        }
      });
    }
  }, [siteCode]);

  const releaseEWISms = () => {
    handleSendSMS(message);
  };

  const onCopyText = () => {
    setCopyStatus(true);
    setTimeout(() => setCopyStatus(false), 5000);
  };

  useEffect(() => {
    if (disseminateData) {
      const {public_alert_level} = disseminateData;
      if (public_alert_level !== 0) {
        let data_timestamp;
        let site_location;
        let site_barangay;
        const {
          event,
          public_alert_symbol,
          releases,
          latest_event_triggers,
          is_onset_release,
        } = disseminateData;
        const {site} = event;
        const {barangay, municipality, province, site_code, sitio} = site;
        const {data_ts, release_time} = releases[0];
        data_timestamp = data_ts;
        if (is_onset_release) {
          const temp_data_ts = data_ts;
          const temp_release_time = release_time;
          const onset_data_ts = `${moment(data_ts).format(
            'YYYY-MM-DD',
          )} ${temp_release_time}`;
          const onset_hour = moment(data_ts).hour();
          if (onset_hour === 23) {
            data_timestamp = moment(onset_data_ts).add(1, 'days');
          }
        }
        const {alert_level} = public_alert_symbol;

        if (sitio != null) {
          site_barangay = `Sitio ${sitio}, Brgy. ${barangay}`;
        } else {
          site_barangay = `Brgy. ${barangay}`;
        }

        setBarangay(site_barangay);
        setSiteCode(site_code);

        site_location = `${site_barangay}, ${municipality}, ${province}`;
        setSiteLocation(site_location);
        
        let msg = `Alert level: ${alert_level}\nLokasyon: ${site_location}\nPetsa at oras: ${moment(
          data_timestamp,
        )
          .add(30, 'minutes')
          .format('MMMM D, YYYY, h:mm A')}`;

        setAlertLevel(`Alert level ${alert_level}`);
        setCurrentAlertTs(
          moment(data_timestamp).add(30, 'minutes').format('LLL'),
        );

        let temp = [...triggerSource];
        if (alert_level > 0) {
          setLatestEventTriggers(latest_event_triggers);
          latest_event_triggers.forEach(trigger => {
            const {internal_sym, trigger_misc, info} = trigger;
            const {trigger_symbol} = internal_sym;
            const {trigger_hierarchy, alert_level} = trigger_symbol;
            const {trigger_source} = trigger_hierarchy;
            let template = ewiTemplates.find(
              e =>
                e.alert_level === alert_level && e.trigger === trigger_source,
            );
            if (trigger_source === 'on demand') {
              const {on_demand} = trigger_misc;
              const {eq_id} = on_demand;
              if (eq_id) {
                template = ewiTemplates.find(
                  e =>
                    e.alert_level === alert_level && e.trigger === 'earthquake',
                );
              } else {
                template = ewiTemplates.find(
                  e =>
                    e.alert_level === alert_level && e.trigger === 'rainfall',
                );
              }
              const trig_source = eq_id
                ? 'On-demand Earthquake'
                : 'On-demand Rainfall';
              msg += `\nBakit (${capitalizeFirstLetter(trig_source)}): ${
                template.trigger_description
              }`;
              temp.push({
                source: capitalizeFirstLetter(trig_source),
                description: template.trigger_description,
                info,
              });
            } else {
              const trig_source =
                trigger_source === 'moms'
                  ? 'Landslide Features'
                  : trigger_source;
              msg += `\nBakit (${capitalizeFirstLetter(trig_source)}): ${
                template.trigger_description
              }`;

              temp.push({
                source: capitalizeFirstLetter(trig_source),
                description: template.trigger_description,
                info,
              });
              setTriggerSource(temp);
            }
          });
        }
        const recommended_response = ewiTemplates.find(
          e => e.alert_level === alert_level,
        );
        setBarangayResponse(recommended_response.barangay_response);
        setLewcResponse(recommended_response.lewc_response);
        setCommunityResponse(recommended_response.community_response);
        setMunicipalResponse(recommended_response.mlgu_response);
        setProvincialResponse(recommended_response.plgu_response);

        msg += `\n\nResponde (Komunidad): ${recommended_response.sms_community_response}\n`;
        msg += `\nResponde (LEWC): ${recommended_response.sms_lewc_response}\n`;
        msg += `\nResponde (Barangay): ${recommended_response.sms_barangay_response}\n\n`;
        msg += `Source: Paranas MDRRMO (+63945 886 6771)`;
        setMessage(msg);
        setCopyMessage(msg);
      } else {
        // need icheck if gagana din sa extended
        const {data_ts, public_alert_level, isRoutine, site_code} = disseminateData;
        const recommended_response = ewiTemplates.find(
          e => e.alert_level === public_alert_level,
        );

        let site_location;
        if (site_code === "scu" ) {
          site_location = `Sitio Campo Uno, Brgy. Tenani`;
        } else {
          site_location = `Brgy. ${CBEWSL_SITE_LOCATION}`
        }
        setSiteLocation(site_location);

        let msg = `\nAlert Level: ${
          recommended_response.alert_level
        }\nLokasyon: ${site_location}\nPetsa at oras: ${moment(data_ts)
          .add(30, 'minutes')
          .format('MMMM D, YYYY, h:mm A')}`;

        setAlertLevel(`Alert Level ${recommended_response.alert_level}`);
        setCurrentAlertTs(moment(data_ts).add(30, 'minutes').format('LLL'));

        let temp = [...triggerSource];
        if (isRoutine === false) {
          temp.push({
            source: 'Extended',
            description: recommended_response.trigger_description,
          });
        }

        setSiteCode(site_code);
        setTriggerSource(temp);
        setCommunityResponse(recommended_response.community_response);
        setBarangayResponse(recommended_response.barangay_response);
        setMunicipalResponse(recommended_response.mlgu_response);
        setProvincialResponse(recommended_response.plgu_response);

        msg += `\nBakit: ${recommended_response.trigger_description}`;
        msg += `\n\nResponde (Komunidad): ${recommended_response.sms_community_response}\n`;
        msg += `\nResponde (LEWC): ${recommended_response.sms_lewc_response}\n`;
        msg += `\nResponde (Barangay): ${recommended_response.sms_barangay_response}\n\n`;
        msg += `Source: Paranas MDRRMO (0945 886 6771)`;
        setMessage(msg);
        setCopyMessage(msg);
      }
    }
  }, [disseminateData]);

  const renderBulletin = () => {
    navigate(`/bulletin`, {
      state: {
        alertLevel: alertLevel,
        siteLocation: siteLocation,
        currentAlertTs: currentAlertTs,
        triggerSource: triggerSource,
        barangayResponse: barangayResponse,
        communityResponse: communityResponse,
        lewcResponse: lewcResponse,
        municipalResponse,
        provincialResponse,
        triggerList: latestEventTriggers,
        siteCode: siteCode,
        barangay: barangay,
        households: households,
      },
    });
  };

  return (
    <Dialog
      fullWidth
      fullScreen={false}
      open={isOpen}
      aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start">
          <Typography variant="h5">Disseminate Warning</Typography>
          <CopyToClipboard text={copyMessage} onCopy={onCopyText}>
            <Tooltip
              title={
                copyStatus ? 'EWI copied to clipboard!' : 'Copy to Clipboard'
              }>
              <IconButton color="primary" size="medium">
                {copyStatus ? (
                  <LibraryAddCheckRoundedIcon fontSize="inherit" />
                ) : (
                  <ContentCopyRoundedIcon fontSize="inherit" />
                )}
              </IconButton>
            </Tooltip>
          </CopyToClipboard>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item md={12}>
            <TextField
              id="filled-multiline-static"
              label="Message"
              multiline
              rows={8}
              variant="filled"
              value={message}
              fullWidth
            />
            {/* <Typography variant="body1"><b>Lugar:</b> {site_location}</Typography>
                        <br />
                        <Typography variant="body1"><b>Petsa at oras:</b> {moment(data_timestamp).add(30, "minutes").format("LLL")}</Typography>
                        <br />
                        <Typography variant="body1"><b>Bakit ({trigger.trigger}):</b> {trigger.waray_tech_info}</Typography>
                        <br />
                        <Typography variant="body1"><b>Responde (komunidad):</b> {trigger.community_response}</Typography>
                        <br />
                        <Typography variant="body1"><b>Responde (LEWC ngan barangay):</b> {trigger.barangay_response}</Typography>
                        <br />
                        <Typography variant="body1"><b>Source:</b> Paranas MDRRMO</Typography>
                        <br /> */}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => setOpenModal(false)}
          color="error">
          Cancel
        </Button>
        <Button variant="contained" onClick={releaseEWISms} color="primary">
          Send EWI SMS
        </Button>
        {alertLevel !== 'Alert level 0' && (
          <Button
            variant="contained"
            onClick={() => {
              renderBulletin();
            }}
            color="primary">
            Generate Bulletin
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default DisseminateModal;
