import React, { Fragment, useState, useEffect, useReducer } from 'react';
import {
  Grid,
  Button,
  Typography,
  TextField,
  Tab,
  Stack,
  Backdrop,
  CircularProgress,
} from '@mui/material';
import LipataHazardMap from '../../assets/lipata_hazard_map.jpg';
import LipataHeader from '../utils/LipataHeader';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MUIDataTable from 'mui-datatables';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import moment from 'moment';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import {
  getAllProfiles,
  saveRiskProfile,
  deleteRiskProfile,
  getMembers,
  updateRiskProfile,
} from './api/risk_profile_api';
import PromptModal from './modals/PromptModal';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

const reducer = (state, action) => {
  switch (action.type) {
    case 'add':
      return [...state, action.payload];
    case 'remove':
      state.splice(action.payload.index, 1);
      return [...state];
    case 'update':
      state[action.payload.index][action.payload.field] = action.payload.value;
      return [...state];
    case 'members':
      return action.payload;
    case 'reset':
      return [];
    default:
      return state;
  }
};

function TBody(props) {
  const { id, setHouseholdMembers } = props;
  const [allMembers, setAllMembers] = useState([]);

  useEffect(() => {
    if (id !== 0) {
      getMembers(id, data => {
        setAllMembers(data.data.members);
        setHouseholdMembers({ type: 'members', payload: data.data.members });
      });
    }
  }, []);

  return (
    <TableBody>
      {allMembers.length > 0 ? (
        allMembers.map(row => (
          <TableRow key={row.first_name}>
            <TableCell align="center">{row.last_name}</TableCell>
            <TableCell align="center">{row.first_name}</TableCell>
            <TableCell align="center">{row.gender}</TableCell>
            <TableCell align="center">
              {moment(new Date(row.birthdate)).format('YYYY-MM-DD')}
            </TableCell>
            <TableCell align="center">{row.disability}</TableCell>
            <TableCell align="center">{row.other_disability}</TableCell>
            <TableCell align="center">{row.pregnant}</TableCell>
          </TableRow>
        ))
      ) : (
        <TableRow>
          <TableCell>No data</TableCell>
        </TableRow>
      )}
    </TableBody>
  );
}

const Assessment = props => {
  //Household Head
  const [houseID, setHouseID] = useState('');
  const [headfirstname, setHeadfirstname] = useState('');
  const [headlastname, setHeadlastname] = useState('');
  const [gender, setGender] = useState('');
  const [bday, setBday] = useState(null);
  const [headDisability, setHeadDisability] = useState(0);
  const [otherDisability, setOtherDisability] = useState('');
  const [headPregnant, setHeadPregnant] = useState(0);
  const [headID, setHeadID] = useState(0);
  const [isEdit, setIsEdit] = useState(false);

  const resetValues = () => {
    setHouseholdMembers({ type: 'reset' });
    setHeadfirstname('');
    setHeadlastname('');
    setGender('');
    setBday(null);
    setHeadDisability('');
    setOtherDisability('');
    setHeadPregnant('');
    setHouseID('');
    setHeadID(0);
    setIsEdit(false);
  };

  const handleGender = event => {
    setGender(event.target.value);
  };
  const handleBday = newValue => {
    setBday(newValue);
  };
  const handleDisability = event => {
    setHeadDisability(event.target.value);
  };
  const handlePregnant = event => {
    setHeadPregnant(event.target.value);
  };

  //Household members
  const [householdMembers, setHouseholdMembers] = useReducer(reducer, []);
  const [deleteID, setDeleteID] = useState(0);

  const handleAddMembers = () => {
    setHouseholdMembers({
      type: 'add',
      payload: {
        first_name: '',
        last_name: '',
        birthdate: '',
        gender: '',
        disability: 0,
        pregnant: 0,
        other_disability: '',
      },
    });
  };

  const handleRemoveField = index => {
    setHouseholdMembers({
      type: 'remove',
      payload: { index: index },
    });
  };

  const handleFieldValues = (i, event, field) => {
    setHouseholdMembers({
      type: 'update',
      payload: {
        index: i,
        value: field === 'birthdate' ? event : event.target.value,
        field,
      },
    });
  };

  //Dialog
  const [open, setOpen] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);
  const [riskProfile, setRiskProfile] = useState([]);
  const [notif_message, setNotifMessage] = useState('');
  const [is_open_prompt_modal, setIsOpenPromptModal] = useState(false);
  const [saveButtonState, setSaveButtonState] = useState(true);
  const [openBackdrop, setOpenBackdrop] = useState(false);

  useEffect(() => {
    if (
      headfirstname === '' ||
      headlastname === '' ||
      houseID === '' ||
      gender === '' ||
      bday === null ||
      headDisability === ''
    ) {
      setSaveButtonState(true);
    } else {
      setSaveButtonState(false);
    }
    return;
  }, [
    headfirstname,
    headlastname,
    saveButtonState,
    houseID,
    gender,
    bday,
    headDisability,
  ]);

  const handleValidation = messsage => {
    setNotifMessage(messsage);
    setIsOpenPromptModal(true);
  };

  const handleOpen = () => {
    setOpen(true);
    setIsConfirm(false);
    resetValues();
  };

  const handleConfirm = () => {
    setOpenConfirm(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenConfirm(false);
    resetValues();
  };

  const handleSubmit = () => {
    setIsConfirm(!isConfirm);
  };

  const getProfiles = () => {
    getAllProfiles(profile => {
      const { data } = profile;
      const temp = [];
      data.forEach(row => {
        temp.push(row);
      });
      setRiskProfile(temp);
    });
  };

  useEffect(() => {
    getProfiles();
  }, []);

  const handleActivity = () => {
    setOpenBackdrop(!openBackdrop);
    setOpen(false);
    const selected_date = moment(new Date(bday)).format('YYYY-MM-DD');
    const updated_members = householdMembers.map(row => {
      const {
        birthdate,
        disability,
        first_name,
        last_name,
        other_disability,
        gender,
        pregnant,
      } = row;

      return {
        m_first: first_name,
        m_last: last_name,
        m_gender: gender,
        m_bday: moment(new Date(birthdate)).format('YYYY-MM-DD'),
        m_disability: parseInt(disability),
        m_pregnant: parseInt(pregnant),
        m_others: other_disability,
      };
    });
    const input = {
      id: headID,
      u_id: 1,
      hh_id: houseID,
      hh_first: headfirstname,
      hh_last: headlastname,
      hh_gender: gender,
      hh_bday: selected_date,
      hh_disability: parseInt(headDisability),
      hh_pregnant: parseInt(headPregnant),
      hh_others: otherDisability,
      hh_members: updated_members,
    };
    if (isEdit) {
      updateRiskProfile(input, data => {
        const { status, message } = data;
        handleValidation(message);
        if (status === true) {
          getProfiles();
          setIsConfirm(false);
          resetValues();
        }
        setOpenBackdrop(false);
      });
    } else {
      saveRiskProfile(input, data => {
        const { status, message } = data;
        handleValidation(message);
        if (status === true) {
          getProfiles();
          resetValues();
        }
        setOpenBackdrop(false);
      });
    }
  };
  const handleDelete = () => {
    deleteRiskProfile({ id: deleteID }, data => {
      const { status, message } = data;
      handleValidation(message);
      if (status === true) {
        getProfiles();
        setOpenConfirm(false);
      }
    });
  };

  const openEditHead = tableMeta => {
    setOpen(true);
    setIsEdit(true);
    setHeadID(tableMeta[0]);
    setHouseID(tableMeta[1]);
    setHeadfirstname(tableMeta[3]);
    setHeadlastname(tableMeta[2]);
    setBday(tableMeta[4]);
    setGender(tableMeta[5]);
    setHeadDisability(tableMeta[6]);
    setOtherDisability(tableMeta[7]);
    setHeadPregnant(tableMeta[8]);
  };

  const columns = [
    {
      name: 'id',
      options: {
        display: false,
      },
    },
    { name: 'household_id', label: <b>Household ID</b> },
    { name: 'last_name', label: <b>Last Name</b> },
    { name: 'first_name', label: <b>First Name</b> },
    { name: 'birthdate', label: <b>Birthday (YYYY/MM/DD)</b> },
    { name: 'gender', label: <b>Gender</b> },
    {
      name: 'disability',
      label: <b>PWD?</b>,
      options: { setCellProps: () => ({ align: 'center' }) },
    },
    {
      name: 'other_disability',
      label: <b>Disabilities</b>,
      options: { setCellProps: () => ({ align: 'center' }) },
    },
    {
      name: 'pregnant',
      label: <b>Pregnant and/or lactating?</b>,
      options: { setCellProps: () => ({ align: 'center' }) },
    },
    {
      name: 'member_count',
      label: <b>No. of household members</b>,
      options: { setCellProps: () => ({ align: 'center' }) },
    },
    {
      name: 'updated_by',
      label: <b>Updated by:</b>,
      options: { setCellProps: () => ({ align: 'center' }) },
    },
    { name: 'updated_at', label: <b>Updated at:</b> },
    {
      name: 'id',
      label: <b>Edit/Delete</b>,
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <Fragment>
              <Stack direction="row">
                <IconButton
                  aria-label="edit"
                  size="small"
                  onClick={() => {
                    openEditHead(tableMeta.rowData);
                    console.log(tableMeta);
                  }}>
                  <EditIcon fontSize="small" />
                </IconButton>
                <IconButton
                  aria-label="delete"
                  size="small"
                  onClick={() => {
                    setDeleteID(value);
                    handleConfirm();
                  }}>
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </Stack>
            </Fragment>
          );
        },
      },
    },
  ];

  const openHouseholds = [];
  const options = {
    sort: false,
    selectableRows: 'none',
    responsive: 'standard',
    rowsPerPage: 10,
    fixedHeader: true,
    expandableRows: true,
    expandableRowsOnClick: true,
    renderExpandableRow: rowData => {
      let id = 0;
      if (!openHouseholds.includes(rowData[0])) {
        id = rowData[0];
      }
      return (
        <Fragment>
          <tr>
            <td colSpan={15}>
              <TableContainer component={Paper}>
                <Table style={{ minWidth: '600' }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">
                        <b>Last name</b>
                      </TableCell>
                      <TableCell align="center">
                        <b>First name</b>
                      </TableCell>
                      <TableCell align="center">
                        <b>Gender</b>
                      </TableCell>
                      <TableCell align="center">
                        <b>Birthday (YYYY/MM/DD)</b>
                      </TableCell>
                      <TableCell align="center">
                        <b>PWD/Diffently-abled?</b>
                      </TableCell>
                      <TableCell align="center">
                        <b>Disability</b>
                      </TableCell>
                      <TableCell align="center">
                        <b>Pregnant?</b>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TBody id={id} setHouseholdMembers={setHouseholdMembers} />
                </Table>
              </TableContainer>
            </td>
          </tr>
        </Fragment>
      );
    },
  };

  return (
    <Fragment>
      <Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          sx={{ marginTop: 2, textAlign: 'center' }}>
          <img
            src={LipataHazardMap}
            alt="lipata-hazard-map-png"
            style={{ objectFit: 'contain', height: '100%', width: '95%' }}
          />
        </Grid>
        <Grid container sx={{ mt: 2, mb: 6, padding: '2%' }}>
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <Button variant="contained" sx={{ mx: 1 }} onClick={handleOpen}>
              Add Household
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <a href={LipataHazardMap} download>
              <Button variant="contained" sx={{ float: 'right', mx: 1 }}>
                Download
              </Button>
            </a>
          </Grid>
        </Grid>
        <Grid item xs={12} sx={{ padding: 2 }}>
          <Typography variant="h4" sx={{ marginBottom: 4 }}>
            Summary of Household
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ marginLeft: 5, marginRight: 5 }}>
          <MUIDataTable
            data={riskProfile}
            columns={columns}
            options={options}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Dialog open={open} onClose={handleClose} scroll={'body'}>
          {!isConfirm ? (
            <Fragment>
              <DialogTitle>
                <b>{isEdit ? 'Edit' : 'Add'} household details</b>
              </DialogTitle>
              <DialogContent>
                <Typography variant="subtitle2">
                  <b>Household id</b>
                </Typography>
                <TextField
                  id="outlined-number"
                  type="number"
                  required
                  value={houseID}
                  error={houseID === null || houseID === ''}
                  onChange={e => setHouseID(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{ marginBottom: 20, maxWidth: 80 }}
                />
                <DialogContentText style={{ marginBottom: 10 }}>
                  {isEdit ? 'Edit' : 'Add'} details for household head
                </DialogContentText>
                <Typography variant="subtitle2">
                  <b>Name of household head</b>
                </Typography>
                <Grid container spacing={2} style={{ marginBottom: 10 }}>
                  <Grid item xs={6}>
                    <TextField
                      id="standard-basic"
                      label="First Name"
                      required
                      value={headfirstname}
                      onChange={e => setHeadfirstname(e.target.value)}
                      error={headfirstname === null || headfirstname === ''}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      id="standard-basic"
                      label="Last Name"
                      value={headlastname}
                      error={headlastname === null || headlastname === ''}
                      onChange={e => setHeadlastname(e.target.value)}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} style={{ marginBottom: 10 }}>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2">
                      <b>Birthday</b>
                    </Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        inputFormat="YYYY/MM/DD"
                        value={bday}
                        required
                        onChange={handleBday}
                        error={bday === null || bday === ''}
                        renderInput={params => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl sx={{ minWidth: 120 }}>
                      <Typography variant="subtitle2">
                        <b>Gender</b>
                      </Typography>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={gender}
                        displayEmpty
                        required
                        error={gender === null || gender === ''}
                        onChange={handleGender}>
                        <MenuItem value={'Male'}>Male</MenuItem>
                        <MenuItem value={'Female'}>Female</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={2} style={{ marginBottom: 5 }}>
                  <Grid item xs={6}>
                    <FormControl>
                      <Typography variant="subtitle2">
                        <b>Do you have any disabilities?</b>
                      </Typography>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={headDisability}
                        defaultValue={headDisability}
                        onChange={handleDisability}
                        required>
                        <FormControlLabel
                          value={1}
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value={0}
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                    {headDisability === '1' && (
                      <Grid container>
                        <TextField
                          id="standard-basic"
                          variant="standard"
                          label="Please state disability"
                          value={otherDisability}
                          onChange={e => setOtherDisability(e.target.value)}
                        />
                      </Grid>
                    )}
                  </Grid>
                  {gender === 'Female' && (
                    <Grid item xs={6}>
                      <Fragment>
                        <FormControl sx={{ minWidth: 120 }}>
                          <Typography variant="subtitle2">
                            <b>Pregnant and/or lactating?</b>
                          </Typography>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            value={headPregnant}
                            defaultValue={0}
                            onChange={handlePregnant}>
                            <FormControlLabel
                              value={1}
                              control={<Radio />}
                              label="Yes"
                            />
                            <FormControlLabel
                              value={0}
                              control={<Radio />}
                              label="No"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Fragment>
                    </Grid>
                  )}
                </Grid>
              </DialogContent>

              {/* Household member details */}

              {householdMembers.length > 0 &&
                householdMembers.map((item, index) => (
                  <Fragment key={`${item}-${index}`}>
                    <DialogContent>
                      <DialogContentText>
                        {isEdit ? 'Edit' : 'Add'} details for household member
                      </DialogContentText>
                      <Grid
                        container
                        spacing={2}
                        direction="row"
                        alignItems="flex-end">
                        <Grid item xs={10} justifyContent="flex-start">
                          <Typography variant="subtitle2">
                            <b>Name of household member</b>
                          </Typography>
                        </Grid>
                        <Grid item xs={2} justifyContent="flex-end">
                          <IconButton
                            aria-label="delete"
                            type="button"
                            size="large"
                            color="error"
                            onClick={() => handleRemoveField(index)}>
                            <DeleteIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} style={{ marginBottom: 10 }}>
                        <Grid item xs={6}>
                          <TextField
                            id="standard-basic"
                            label="First Name"
                            value={item.first_name}
                            onChange={e =>
                              handleFieldValues(index, e, 'first_name')
                            }
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            id="standard-basic"
                            label="Last Name"
                            value={item.last_name}
                            onChange={e =>
                              handleFieldValues(index, e, 'last_name')
                            }
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} style={{ marginBottom: 10 }}>
                        <Grid item xs={6}>
                          <Typography variant="subtitle2">
                            <b>Birthday</b>
                          </Typography>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DesktopDatePicker
                              inputFormat="YYYY/MM/DD"
                              value={item.birthdate}
                              onChange={e =>
                                handleFieldValues(index, e, 'birthdate')
                              }
                              renderInput={params => <TextField {...params} />}
                            />
                          </LocalizationProvider>
                        </Grid>
                        <Grid item xs={6}>
                          <FormControl sx={{ minWidth: 120 }}>
                            <Typography variant="subtitle2">
                              <b>Gender</b>
                            </Typography>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={item.gender}
                              displayEmpty
                              onChange={e =>
                                handleFieldValues(index, e, 'gender')
                              }>
                              <MenuItem value={'Male'}>Male</MenuItem>
                              <MenuItem value={'Female'}>Female</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} style={{ marginBottom: 5 }}>
                        <Grid item xs={6}>
                          <FormControl sx={{ minWidth: 120 }}>
                            <Typography variant="subtitle2">
                              <b>Do you have any disabilities?</b>
                            </Typography>
                            <RadioGroup
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="row-radio-buttons-group"
                              value={item.disability}
                              default={item.disability}
                              onChange={e =>
                                handleFieldValues(index, e, 'disability')
                              }>
                              <FormControlLabel
                                value={1}
                                control={<Radio />}
                                label="Yes"
                              />
                              <FormControlLabel
                                value={0}
                                control={<Radio />}
                                label="No"
                              />
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                        {item.gender === 'Female' && (
                          <Grid item xs={6}>
                            <Fragment>
                              <FormControl sx={{ minWidth: 120 }}>
                                <Typography variant="subtitle2">
                                  <b>Pregnant and/or lactating?</b>
                                </Typography>
                                <RadioGroup
                                  row
                                  aria-labelledby="demo-row-radio-buttons-group-label"
                                  name="row-radio-buttons-group"
                                  value={item.pregnant}
                                  defaultValue={item.pregnant}
                                  onChange={e =>
                                    handleFieldValues(index, e, 'pregnant')
                                  }>
                                  <FormControlLabel
                                    value={1}
                                    control={<Radio />}
                                    label="Yes"
                                  />
                                  <FormControlLabel
                                    value={0}
                                    control={<Radio />}
                                    label="No"
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Fragment>
                          </Grid>
                        )}
                      </Grid>
                      {item.disability === '1' && (
                        <Grid container>
                          <TextField
                            id="standard-basic"
                            variant="standard"
                            label="Please state disability"
                            value={item.other_disability}
                            onChange={e =>
                              handleFieldValues(index, e, 'other_disability')
                            }
                          />
                        </Grid>
                      )}
                    </DialogContent>
                  </Fragment>
                ))}
              <DialogContent>
                <Grid container spacing={2} style={{ marginBottom: 5 }}>
                  <Stack direction="row">
                    <Button variant="contained" onClick={handleAddMembers}>
                      Add member
                    </Button>
                  </Stack>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button variant="contained" color="error" onClick={handleClose}>
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={handleSubmit}
                  disabled={saveButtonState}>
                  Save
                </Button>
              </DialogActions>
            </Fragment>
          ) : (
            <Fragment>
              <DialogTitle>Are you sure?</DialogTitle>
              <DialogContent>
                <DialogContentText>Household details:</DialogContentText>
                <Typography variant="subtitle2">
                  Household ID: {houseID}
                </Typography>
                <Typography variant="subtitle2">
                  Household head name: {headfirstname} {headlastname}
                </Typography>
                <Typography variant="subtitle2">
                  Household member count: {householdMembers.length}
                </Typography>
              </DialogContent>
              <DialogActions>
                <Button
                  variant="contained"
                  color="error"
                  onClick={handleSubmit}>
                  No
                </Button>
                <Button variant="contained" onClick={handleActivity}>
                  Yes
                </Button>
              </DialogActions>
            </Fragment>
          )}
        </Dialog>
        <Dialog open={openConfirm}>
          <DialogContent>
            Are you sure deleting this risk profile?
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={handleClose}>
              No
            </Button>
            <Button variant="contained" onClick={handleDelete}>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
        <PromptModal
          isOpen={is_open_prompt_modal}
          setOpenModal={setIsOpenPromptModal}
          handleValidation={handleValidation}
          notifMessage={notif_message}
        />
        <Grid>
          <Backdrop
            sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 9999 }}
            open={openBackdrop}>
            <CircularProgress color="inherit" />
          </Backdrop>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default Assessment;
