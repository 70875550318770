import React from 'react';
import {img} from 'react';
import {
  Grid,
  Typography,
  Button,
  Box,
  TextField,
  FormControl,
  IconButton,
  Backdrop,
  CircularProgress,
  Dimension,
} from '@mui/material';
import DostSeal from '../../assets/phivolcs_seal.png';
import DynaslopeSealMini from '../../assets/dynaslope_seal.png';
import SamarSeal from '../../assets/samar_seal.png';
import ParanasSeal from '../../assets/paranas_seal.png';
import PdrrmcSeal from '../../assets/pdrrmc_seal.png';
import MdrrmoSeal from '../../assets/mdrrmo_seal.png';
import Googleplay from '../../assets/Google_Play-Logo.png';
import BrgySeal from '../../assets/brgy_seal.png';
import axios from 'axios';
import fileDownload from 'js-file-download';

const Download = () => {
  const {innerWidth, innerHeight} = window;

  const handleDownload = (url, filename) => {
    // SET URL TO DOWNLOAD URL
    axios
      .get(url, {
        responseType: 'blob',
      })
      .then(res => {
        fileDownload(res.data, filename);
      });
  };

  return (
    <Grid
      container
      sx={{padding: 8, textAlign: 'center'}}
      justifyContent={'center'}>
      <Grid item xs={12}>
        <img
          src={DostSeal}
          alt="paranas-seal-png"
          style={{
            objectFit: 'contain',
            height: innerWidth * 0.1,
            width: innerWidth * 0.1,
            minWidth: 100,
            minHeight: 80,
            padding: 10,
          }}
        />
        <img
          src={DynaslopeSealMini}
          alt="paranas-seal-png"
          style={{
            objectFit: 'contain',
            height: innerWidth * 0.1,
            width: innerWidth * 0.1,
            minWidth: 100,
            minHeight: 80,
            padding: 10,
          }}
        />
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          display: 'inline-table',
          width: innerWidth,
          textAlign: 'center',
        }}>
        <img
          src={SamarSeal}
          alt="paranas-seal-png"
          style={{
            objectFit: 'contain',
            height: innerWidth * 0.1,
            width: innerWidth * 0.1,
            minWidth: 50,
            minHeight: 50,
            paddingRight: 10,
          }}
        />
        <img
          src={PdrrmcSeal}
          alt="paranas-seal-png"
          style={{
            objectFit: 'contain',
            height: innerWidth * 0.1,
            width: innerWidth * 0.1,
            minWidth: 50,
            minHeight: 50,
            paddingRight: 10,
          }}
        />
        <img
          src={MdrrmoSeal}
          alt="paranas-seal-png"
          style={{
            objectFit: 'contain',
            height: innerWidth * 0.1,
            width: innerWidth * 0.1,
            minWidth: 50,
            minHeight: 50,
            paddingRight: 10,
          }}
        />
        <img
          src={BrgySeal}
          alt="paranas-seal-png"
          style={{
            objectFit: 'contain',
            height: innerWidth * 0.1,
            width: innerWidth * 0.1,
            minWidth: 50,
            minHeight: 50,
            paddingRight: 10,
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="h3"
          style={{
            fontWeight: 'bold',
            padding: 10,
            color: '#16526D',
            fontSize: innerWidth < 750 ? 20 : 50,
            paddingTop: 20,
          }}>
          COMMUNITY-BASED EARLY WARNING SYSTEM FOR LANDSLIDES
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="h6"
          style={{fontWeight: 'bold', padding: 10, color: '#16526D'}}>
          CBEWS-L App will be soon available on
        </Typography>
        {console.log(innerWidth)}
        <img
          src={Googleplay}
          alt="paranas-seal-png"
          style={{
            objectFit: 'contain',
            height: innerWidth * 0.2,
            width: innerWidth * 0.35,
            maxHeight: 200,
            maxWidth: 300,
            marginTop: innerWidth < 750 ? -30 : -50,
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="h6"
          style={{fontWeight: 'bold', padding: 10, color: '#16526D'}}>
          You can download the app by clicking
        </Typography>
        <a
          href="https://paranasdynaslope.com:8000/app-release.apk"
          style={{textDecoration: 'none'}}>
          <Button
            variant="contained"
            sx={{backgroundColor: '#F8991D'}}
            // onClick={handleDownload(
            //   'exp://exp.host/@jgeliberte/ExpoSamarCluster?release-channel=default',
            // )}
          >
            DOWNLOAD
          </Button>
        </a>
      </Grid>
    </Grid>
  );
};

export default Download;
