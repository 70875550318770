import axios from 'axios';
const API_URL = `${process.env.REACT_APP_API_URL}`;

export const getAllHouseholds = (site_id, callback) => {
  axios
    .get(`${API_URL}/api/household_data/get_all/${site_id}`)
    .then(response => {
      callback(response.data);
    })
    .catch(error => {});
};

export const getSummary = (site_id, callback) => {
  axios
    .get(`${API_URL}/api/household_data/get_summary/${site_id}`)
    .then(response => {
      callback(response.data);
    })
    .catch(error => {});
};

export const getPregnant = (site_id, callback) => {
  axios
    .get(`${API_URL}/api/household_data/get_pregnant/${site_id}`)
    .then(response => {
      callback(response.data);
    })
    .catch(error => {});
};

export const getComorbid = (site_id, callback) => {
  axios
    .get(`${API_URL}/api/household_data/get_comorbidity/${site_id}`)
    .then(response => {
      callback(response.data);
    })
    .catch(error => {});
};

export const getDisabled = (site_id, callback) => {
  axios
    .get(`${API_URL}/api/household_data/get_disability/${site_id}`)
    .then(response => {
      callback(response.data);
    })
    .catch(error => {});
};

export const getSenior = (site_id, callback) => {
  axios
    .get(`${API_URL}/api/household_data/get_seniors/${site_id}`)
    .then(response => {
      callback(response.data);
    })
    .catch(error => {});
};

export const getChildren = (site_id, callback) => {
  axios
    .get(`${API_URL}/api/household_data/get_children/${site_id}`)
    .then(response => {
      callback(response.data);
    })
    .catch(error => {});
};

export const getToddler = (site_id, callback) => {
  axios
    .get(`${API_URL}/api/household_data/get_toddlers/${site_id}`)
    .then(response => {
      callback(response.data);
    })
    .catch(error => {});
};

export const addHousehold = (data, callback) => {
  axios
    .post(`${API_URL}/api/household_data/add`, data)
    .then(response => {
      callback(response.data);
    })
    .catch(error => {});
};

export const editHousehold = (data, callback) => {
  axios
    .patch(`${API_URL}/api/household_data/update`, data)
    .then(response => {
      callback(response.data);
    })
    .catch(error => {});
};

export const deleteHousehold = (submitData, callback) => {
  axios
    .delete(`${API_URL}/api/household_data/delete`, {data: submitData})
    .then(response => {
      callback(response.data);
    })
    .catch(error => {});
};
