import { Box, Grid } from "@mui/material";
import React, { Fragment } from "react";
import GeneratedBulletin from "./GeneratedBulletin";

const Bulletin = () => {
  return (
    <Fragment>
      <Grid container justifyContent="center" alignItems="flex-start">
        <GeneratedBulletin/>
      </Grid>
    </Fragment>
   
  );
};

export default Bulletin;
