import React, {Fragment, useEffect} from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  // Link
} from 'react-router-dom';

import ChartRenderingContainer from './components/Chart_rendering/Container';
import Signin from './components/authentication/Signin';
import Dashboard from './components/lipata/Dashboard';
import OpCen from './components/lipata/OpCen';
import Events from './components/lipata/Events';
import Communication from './components/lipata/Communication';
import Analysis from './components/lipata/Analysis';
import Assessment from './components/lipata/Assessment';
import CapacityAndVulnerability from './components/lipata/CapacityAndVulnerability';
import LipataHeader from './components/utils/LipataHeader';
import Feedback from './components/lipata/Feedback';
import ChangePassword from './components/utils/ChangePassword';
import Rainfall from './components/lipata/Rainfall';
import Subsurface from './components/lipata/Subsurface';
import Surficial from './components/lipata/Surficial';
import SurficialMarkers from './components/lipata/SurficialMarkers';
import Moms from './components/lipata/Moms';
import Earthquake from './components/lipata/Earthquake';
import Resources from './components/lipata/Resources';
import Bulletin from './components/utils/Bulletin';
import LandslideFeaturesTable from './components/analysis/LandslideFeaturesTable';
import PublicFeedback from './components/lipata/PublicFeedback';
import TermsOfUse from './components/utils/TermsOfUse';
import Download from './components/lipata/Download';
import PrivacyPolicy from './components/lipata/PrivacyPolicy';
import ProfileSetting from './components/utils/ProfileSetting';

const App = props => {
  const Header = () => {
    let location = window.location.pathname;
    if (
      location !== '/signin' &&
      location !== '/' &&
      location !== '/download'
    ) {
      if (localStorage.getItem('data') !== null) {
        return <LipataHeader />;
      } else {
        window.location = '/signin';
      }
    } else {
      if (localStorage.getItem('data') !== null) {
        window.location = '/opcen';
      }
    }
  };

  return (
    <Fragment>
      <Router>
        {Header()}
        <Routes>
          <Route exact path="" element={<Signin />} />
          <Route exact path="/signin" element={<Signin />} />
        </Routes>

        {localStorage.getItem('data') != null && (
          <Routes>
            <Route exact path="/opcen" element={<OpCen />} />
            <Route exact path={'/bulletin'} element={<Bulletin />} />
            <Route exact path="/events" element={<Events />} />
            <Route exact path="/communication" element={<Communication />} />
            <Route exact path="/analysis/:site_code" element={<Analysis />} />
            <Route exact path="/rainfall/:site_code" element={<Rainfall />} />
            <Route
              exact
              path="/subsurface/:site_code"
              element={<Subsurface />}
            />
            <Route exact path="/surficial/:site_code" element={<Surficial />} />
            <Route
              exact
              path="/earthquake/:site_code"
              element={<Earthquake />}
            />
            {/* <Route exact path="/moms" element={<LandslideFeaturesTable />} /> */}
            <Route exact path="/all_charts" element={<Analysis />} />
            <Route
              exact
              path="/assessment/:site_code"
              element={<CapacityAndVulnerability />}
            />
            <Route exact path="/resources" element={<Resources />} />
            <Route exact path="/feedback" element={<Feedback />} />
            <Route exact path="/terms-of-use" element={<TermsOfUse />} />
            <Route exact path="/public/feedback" element={<PublicFeedback />} />
            <Route exact path="/download" element={<Download />} />
            <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route exact path="/change-password" element={<ChangePassword />} />
            <Route exact path="/profile-setting" element={<ProfileSetting />} />
            <Route
              exact
              path={`/surficial_markers/:site_code`}
              element={<SurficialMarkers />}
            />
            <Route exact path={`/moms/:site_code`} element={<Moms />} />
            <Route
              path="*"
              element={
                <main style={{padding: '1rem'}}>
                  <h2>Webpage not found</h2>
                </main>
              }
            />
          </Routes>
        )}
      </Router>
    </Fragment>
  );
};

export default App;
