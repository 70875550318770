import axios from 'axios';
const API_URL = `${process.env.REACT_APP_API_URL}`;

export const getTableSurficial = (data, callback) => {
  axios
    .get(
      `${API_URL}/api/surficial/tabled_marker_observations/${data.site_code}/${data.startDate}/${data.endDate}`,
    )
    .then(response => {
      callback(response.data);
    })
    .catch(error => {});
};

export const getSurficialData = (data, callback) => {
  axios
    .get(
      `${API_URL}/api/surficial/get_surficial_plot_data/24/${data.startDate}/${data.endDate}`,
    )
    .then(response => {
      callback(response.data);
    })
    .catch(error => {});
};

export const sendMeasurement = (data, callback) => {
  axios
    .post(`${API_URL}/api/surficial/insert_web`, data)
    .then(response => {
      callback(response.data);
    })
    .catch(error => {});
};

export const deletePrevMeasurement = (data, callback) => {
  axios
    .post(`${API_URL}/api/surficial/delete_prev_measurement`, {mo_id: data})
    .then(response => {
      callback(response.data);
    })
    .catch(error => {});
};

export const deleteMeasurement = (data, callback) => {
  axios
    .post(`${API_URL}/api/surficial/delete_surficial_data`, {
      quantity: 'all',
      id: data,
    })
    .then(response => {
      callback(response.data);
    })
    .catch(error => {});
};

export const getStaffs = (site_id, callback) => {
  axios
    .get(`${API_URL}/api/misc/get_all_staff_users/${site_id}`)
    .then(response => {
      callback(response.data);
    })
    .catch(error => {});
};
