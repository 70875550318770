import React, {Fragment, useEffect, useState, useRef} from 'react';
import {
  Grid,
  Button,
  InputAdornment,
  TextField,
  Card,
  CardContent,
  IconButton,
} from '@mui/material';
import LipataHeader from '../utils/LipataHeader';
import {Box} from '@mui/system';
import Paper from '@mui/material/Paper';
import SendIcon from '@mui/icons-material/Send';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Stack from '@mui/material/Stack';
import EditIcon from '@mui/icons-material/Edit';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Typography from '@mui/material/Typography';
import ListItemButton from '@mui/material/ListItemButton';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Avatar from '@mui/material/Avatar';
import AttachmentIcon from '@mui/icons-material/Attachment';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Autocomplete from '@mui/material/Autocomplete';
import LocalPoliceOutlinedIcon from '@mui/icons-material/LocalPoliceOutlined';
import LocalHospitalOutlinedIcon from '@mui/icons-material/LocalHospitalOutlined';
import FireTruckOutlinedIcon from '@mui/icons-material/FireTruckOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import SecurityOutlinedIcon from '@mui/icons-material/SecurityOutlined';
import OfflineBoltOutlinedIcon from '@mui/icons-material/OfflineBoltOutlined';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import MessageTwoToneIcon from '@mui/icons-material/MessageTwoTone';
import {
  getConversations,
  getRooms,
  getUsers,
  sendMessage,
} from './api/chats_api';
import LewcSeal from '../../assets/lewc_seal.png';
import moment from 'moment';
import {base_url, WSS_KEY} from '../host';
import {io} from 'socket.io-client';
const Communication = () => {
  const [tabValue, setTabValue] = useState('2');
  const [selectedIndex, setSelectedIndex] = useState(1);
  const [expanded, setExpanded] = useState(false);
  const [characterLimit, setCharacterLimit] = useState(350);
  const [message, setMessage] = useState('');
  const [open, setOpen] = useState(false);
  const [openConvoDialog, setOpenConvoDialog] = useState(false);
  const [chatRooms, setChatRooms] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [selectedContact, setSelectedContact] = useState('');
  const [selectedRoomID, setSelectedRoomID] = useState(null);
  const [conversations, setConversations] = useState([]);
  const [isNewMsg, setIsNewMsg] = useState(false);
  const [roomName, setRoomName] = useState('');
  const [current_user, setCurrentUser] = useState(null);
  const [all_contacts, setAllContacts] = useState([]);
  const socketRef = useRef();

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleListItemClick = (id, room_name) => {
    setRoomName(room_name);
    setSelectedRoomID(id);
    setSelectedContact(null);
    getConversations(id, rooms => {
      const {data} = rooms;
      const temp = [];
      data.forEach(row => {
        temp.push(row);
      });
      setConversations(temp.reverse());
    });
  };

  useEffect(() => {
    setCharacterLimit(350 - message.length);
  }, [message]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleOpenConvo = () => {
    setOpenConvoDialog(true);
    setIsNewMsg(true);
    setSelectedRoomID(null);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenConvoDialog(false);
    setIsNewMsg(false);
  };

  const handleSend = () => {
    setOpenConvoDialog(false);
    setMessage('');
    console.log(selectedContact);
    const {
      profile: {user_id},
    } = current_user;
    let recipient = conversations.find(e => e.sender_user_id);
    console.log('conv', conversations);
    console.log('recipient', recipient);
    let recipient_id =
      recipient.sender_user_id === user_id
        ? recipient.recipient_user_id
        : recipient.sender_user_id;
    // console.log(recipient)
    const input = {
      sender_user_id: user_id.toString(),
      recipient_user_id: isNewMsg
        ? selectedContact.user_id.toString()
        : recipient_id.toString(),
      msg: message,
      room_id: isNewMsg ? null : selectedRoomID.toString(),
    };
    console.log('input', input);
    socketRef.current.emit('message', {
      code: 'send_msg',
      key: WSS_KEY,
      req: input,
    });
    //     const recipient = contacts.find(e => e.user_id === recipient_user_id);
    //     const { name } = recipient;
    //     const full_name = name.split(", ")
    //     handleListItemClick(data.res.room_id, `You and ${full_name[1]} ${full_name[0]}`);
    // sendMessage(input, data => {
    //   const { status } = data;
    //   if (status === true) {
    //     console.log(data.res);
    //     setConversations([...conversations, data.res]);
    //     const { recipient_user_id } = data.res;
    //     const recipient = contacts.find(e => e.user_id === recipient_user_id);
    //     const { name } = recipient;
    //     const full_name = name.split(", ")
    //     handleListItemClick(data.res.room_id, `You and ${full_name[1]} ${full_name[0]}`);
    //   }
    //   handleConvo(current_user);
    // });
  };

  const handleConvo = data => {
    if (data) {
      const {
        user: {user_id},
      } = data;
      getRooms(user_id, rooms => {
        const {data} = rooms;
        const temp = [];
        data.forEach(row => {
          temp.push(row);
        });
        setChatRooms(temp.reverse());
      });
    }
  };

  const getContacts = () => {
    getUsers(userData => {
      setContacts(userData);
    });
  };

  useEffect(() => {
    const data = localStorage.getItem('data');
    const parse_data = JSON.parse(data);
    setCurrentUser(parse_data);
    handleConvo(parse_data);
    getContacts();
  }, []);

  useEffect(() => {
    const data = localStorage.getItem('data');
    const parse_data = JSON.parse(data);
    const {
      profile: {user_id},
    } = parse_data;
    console.log(user_id);
    console.log(`${base_url}:6060`);
    socketRef.current = io(`${base_url}:6060`, {
      transports: ['websocket'],
      auth: {
        Authorization: WSS_KEY,
        user_id: user_id,
      },
    });
    socketRef.current.on('message', resp => {
      let response = JSON.parse(resp);
      let code = response.code;
      let res = response.res;
      console.log('HEREEEEE');
      switch (code) {
        case 'chat_recieved':
          let temp = [...conversations];
          temp.push({
            source: res.sender_user_id === user_id ? 'You' : res.source,
            mobile_no: 'N/A',
            msg: res.msg,
            ts: moment(res.ts).format('YYYY-MM-DD hh:mm:ss A'),
          });
          console.log(temp);
          setConversations(temp.reverse());
          break;
        default:
          break;
      }
    });
    return () => {
      socketRef.current.disconnect();
    };
  }, [conversations]);

  const MessageRight = message => {
    return (
      <div style={{display: 'flex', justifyContent: 'flex-end', padding: 10}}>
        <div style={{marginRight: 10}}>
          <Box
            sx={{
              backgroundColor: '#0078FF',
              '&:hover': {
                backgroundColor: '#0078FF',
                opacity: [0.9, 0.8, 0.7],
              },
              padding: 1,
              borderRadius: 5,
            }}>
            <Typography variant="h6" color={'white'}>
              {message.data.msg}
            </Typography>
            <Typography
              variant="subtitle1"
              style={{textAlign: 'right', fontSize: 12}}
              color={'white'}>
              {moment(message.data.ts).format('YYYY-MM-DD hh:mmA')}
            </Typography>
          </Box>
        </div>
        <div>
          <Avatar
            sx={{bgcolor: 'gray'}}
            alt={conversations.source}
            src="/broken-image.jpg"
          />
        </div>
      </div>
    );
  };

  const MessageLeft = message => (
    <div style={{display: 'flex', justifyContent: 'flex-start', padding: 10}}>
      <div>
        <Avatar
          sx={{bgcolor: 'gray'}}
          alt={message.data.source}
          src="/broken-image.jpg"
        />
      </div>
      <div style={{marginLeft: 10}}>
        <Box
          sx={{
            backgroundColor: '#dcdcdc',
            '&:hover': {
              backgroundColor: '#dcdcdc',
              opacity: [0.9, 0.8, 0.7],
            },
            padding: 1,
            borderRadius: 5,
          }}>
          <Typography variant="h6">{message.data.msg}</Typography>
          <Typography variant="subtitle1" style={{fontSize: 12}}>
            {moment(message.data.ts).format('YYYY-MM-DD hh:mmA')}
          </Typography>
        </Box>
      </div>
    </div>
  );

  return (
    <Fragment>
      <Grid
        container
        justifyContent={'center'}
        alignItems={'left'}
        textAlign={'left'}
        padding={5}
        spacing={3}>
        <Grid item xs={4}>
          <Box
            style={{
              padding: 10,
              backgroundColor: '#e1f5fe',
              maxHeight: window.screen.height - 400,
              minHeight: 670,
              borderTopLeftRadius: 10,
              borderTopRightRadius: 60,
            }}>
            <Grid container>
              <Box sx={{width: '100%', typography: 'body1'}}>
                <TabContext value={tabValue}>
                  <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                    <TabList
                      onChange={handleTabChange}
                      variant="fullWidth"
                      aria-label="lab API tabs example">
                      <Tab label="Contacts" value="1" />
                      <Tab label="Inbox" value="2" />
                    </TabList>
                  </Box>
                  <TabPanel value="2">
                    <Grid container alignItems={'center'} spacing={1}>
                      <Grid item xs={9} style={{marginBottom: 5}}>
                        <Paper
                          component="form"
                          sx={{
                            p: '2px 4px',
                            display: 'flex',
                            alignItems: 'center',
                          }}>
                          <Autocomplete
                            id="combo-box-demo"
                            options={chatRooms}
                            fullWidth
                            getOptionLabel={option => option.room_name || ' '}
                            onChange={(event, value) =>
                              handleListItemClick(value.id, value.room_name)
                            }
                            renderInput={params => (
                              <TextField {...params} label="Search inbox" />
                            )}
                          />
                        </Paper>
                      </Grid>
                      <Grid item xs={3} style={{marginBottom: 5}}>
                        <Grid container>
                          <Button
                            size="medium"
                            variant="contained"
                            endIcon={<MessageTwoToneIcon />}
                            onClick={handleOpenConvo}>
                            Compose
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={12}>
                        <Box sx={{bgcolor: 'white'}}>
                          <div
                            style={{
                              maxHeight: 300,
                              minHeight: 300,
                              overflowY: 'scroll',
                            }}>
                            <List
                              sx={{
                                width: '100%',
                                maxWidth: 'true',
                                bgcolor: 'background.paper',
                              }}>
                              {chatRooms.map(item => {
                                const {id, last_msg, last_ts, room_name} = item;
                                return (
                                  <Fragment>
                                    <ListItemButton
                                      onClick={event =>
                                        handleListItemClick(id, room_name)
                                      }>
                                      <ListItem alignItems="flex-start">
                                        <ListItemAvatar>
                                          <Avatar
                                            sx={{bgcolor: 'gray'}}
                                            alt={item.sender}
                                            src="/broken-image.jpg"
                                          />
                                        </ListItemAvatar>
                                        <ListItemText
                                          primary={room_name}
                                          secondary={
                                            <Fragment>
                                              <Typography
                                                sx={{display: 'inline'}}
                                                component="span"
                                                variant="body2"
                                                color="text.primary">
                                                {last_ts}
                                              </Typography>
                                              {` - ${last_msg}`}
                                            </Fragment>
                                          }
                                        />
                                      </ListItem>
                                    </ListItemButton>
                                    <Divider variant="left" component="li" />
                                  </Fragment>
                                );
                              })}
                            </List>
                          </div>
                        </Box>
                      </Grid>
                    </Grid>
                  </TabPanel>

                  <TabPanel value="1">
                    <Grid item xs={12} marginTop={1} marginBottom={1}>
                      <Stack direction="row">
                        <Button
                          variant="contained"
                          startIcon={
                            <AddCircleOutlineIcon style={{fontSize: 'large'}} />
                          }
                          fullWidth
                          size="small"
                          disabled>
                          Create new contact
                        </Button>
                        <Divider orientation="vertical" flexItem />
                        <Button
                          variant="contained"
                          startIcon={<EditIcon style={{fontSize: 'large'}} />}
                          fullWidth
                          size="small"
                          disabled
                          onClick={handleOpen}>
                          Edit contact
                        </Button>
                      </Stack>
                    </Grid>
                    <Grid item xs={12}>
                      <Box sx={{bgcolor: 'white'}}>
                        <div
                          style={{
                            maxHeight: 300,
                            minHeight: 300,
                            overflowY: 'scroll',
                          }}>
                          <List
                            sx={{
                              width: '100%',
                              maxWidth: 'true',
                              bgcolor: 'background.paper',
                            }}>
                            {contacts.map(item => {
                              const {user_id, name} = item;
                              return (
                                <Fragment>
                                  <ListItemButton
                                    selected={selectedIndex === 0}
                                    onClick={event =>
                                      handleListItemClick(user_id)
                                    }>
                                    <ListItem alignItems="flex-start">
                                      <ListItemAvatar>
                                        <Avatar
                                          sx={{bgcolor: 'gray'}}
                                          alt={item.sender}
                                          src="/broken-image.jpg"
                                        />
                                      </ListItemAvatar>
                                      <ListItemText
                                        primary={name}
                                        // secondary={
                                        //   <Fragment>
                                        //     <Typography
                                        //       sx={{display: 'inline'}}
                                        //       component="span"
                                        //       variant="body2"
                                        //       color="text.primary">
                                        //       aaaa
                                        //     </Typography>
                                        //   </Fragment>
                                        // }
                                      />
                                    </ListItem>
                                  </ListItemButton>
                                  <Divider variant="left" component="li" />
                                </Fragment>
                              );
                            })}
                          </List>
                        </div>
                      </Box>
                    </Grid>
                  </TabPanel>
                </TabContext>
              </Box>

              <Grid item xs={12}>
                <Card style={{minHeight: 200, maxHeight: 220}}>
                  <CardContent>
                    <Grid container>
                      <Grid item xs={11}>
                        <Typography color="text.primary">
                          For emergency, please contact:
                        </Typography>
                      </Grid>
                      <Grid item xs={1}>
                        <IconButton aria-label="edit" size="small">
                          <EditIcon fontSize="small" />
                        </IconButton>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      item
                      xs={12}
                      direction="row"
                      alignItems={'center'}>
                      <AccountBalanceOutlinedIcon style={{marginRight: 5}} />
                      <Typography style={{marginRight: 5}}>
                        <b>PDRRMO:</b>
                      </Typography>
                      <Typography>(055) 530 0609</Typography>
                    </Grid>
                    <Grid
                      container
                      item
                      xs={12}
                      direction="row"
                      alignItems={'center'}>
                      <AccountBalanceOutlinedIcon style={{marginRight: 5}} />
                      <Typography style={{marginRight: 5}}>
                        <b>Mayor's Office:</b>
                      </Typography>
                      <Typography>
                        (+63)939 200 4813 / (055) 533 1342
                      </Typography>
                    </Grid>
                    <Grid
                      container
                      item
                      xs={12}
                      direction="row"
                      alignItems={'center'}>
                      <SecurityOutlinedIcon style={{marginRight: 5}} />
                      <Typography style={{marginRight: 5}}>
                        <b>MDRRMO:</b>
                      </Typography>
                      <Typography>(+63)945 886 6771</Typography>
                    </Grid>
                    <Grid
                      container
                      item
                      xs={12}
                      direction="row"
                      alignItems={'center'}>
                      <FireTruckOutlinedIcon style={{marginRight: 5}} />
                      <Typography style={{marginRight: 5}}>
                        <b>BFP:</b>
                      </Typography>
                      <Typography>(+63)955 723 3327</Typography>
                    </Grid>
                    <Grid
                      container
                      item
                      xs={12}
                      direction="row"
                      alignItems={'center'}>
                      <LocalPoliceOutlinedIcon style={{marginRight: 5}} />
                      <Typography style={{marginRight: 5}}>
                        <b>PNP:</b>
                      </Typography>
                      <Typography>(+63)945 249 1547</Typography>
                    </Grid>
                    <Grid
                      container
                      item
                      xs={12}
                      direction="row"
                      alignItems={'center'}>
                      <OfflineBoltOutlinedIcon style={{marginRight: 5}} />
                      <Typography style={{marginRight: 5}}>
                        <b>SAMELCO II:</b>
                      </Typography>
                      <Typography>(+63)916 660 3276</Typography>
                    </Grid>
                    <Grid
                      container
                      item
                      xs={12}
                      direction="row"
                      alignItems={'center'}>
                      <LocalHospitalOutlinedIcon style={{marginRight: 5}} />
                      <Typography style={{marginRight: 5}}>
                        <b>RHU:</b>
                      </Typography>
                      <Typography>(+63)906 220 3385</Typography>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        {selectedRoomID !== null ? (
          <Grid item xs={6}>
            <Grid container>
              <Typography variant="h5">{roomName}</Typography>
              <Box
                sx={{
                  width: '100%',
                  bgcolor: 'background.paper',
                  maxHeight: window.screen.height - 600,
                  minHeight: 600,
                  overflowY: 'scroll',
                }}>
                <Divider variant="left" />
                <Grid
                  item
                  xs={12}
                  style={{
                    maxHeight: window.screen.height - 400,
                    minHeight: 600,
                  }}>
                  {conversations.map(message => {
                    const {sender_user_id} = message;
                    return (
                      <Fragment>
                        {sender_user_id === current_user.profile.user_id ? (
                          <MessageRight data={message} />
                        ) : (
                          <MessageLeft data={message} />
                        )}
                      </Fragment>
                    );
                  })}
                </Grid>
                <Divider variant="left" />
              </Box>
            </Grid>
            <Grid container>
              <Box sx={{width: '100%', bgcolor: 'background.paper'}}>
                <Grid item xs={12}>
                  <TextField
                    id="input-with-icon-textfield"
                    label="Message"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          {message.length !== 0 && (
                            <IconButton onClick={() => setMessage('')}>
                              <HighlightOffIcon />
                            </IconButton>
                          )}
                          <IconButton>
                            <AttachmentIcon style={{padding: 5}} />
                          </IconButton>
                          <IconButton onClick={handleSend}>
                            <SendIcon color="primary" style={{padding: 5}} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    value={message}
                    onChange={e => setMessage(e.target.value)}
                    inputProps={{maxLength: 250}}
                    style={{width: '100%'}}
                    variant="standard"
                    multiline
                    helperText={`${characterLimit} characters left.`}
                  />
                </Grid>
              </Box>
            </Grid>
          </Grid>
        ) : (
          <Grid item xs={6}>
            <Grid container>
              {/* <img
                src={LewcSeal}
                alt="lewc-seal-png"
                style={{
                  opacity: 0.3,
                  objectFit: 'contain',
                  height: 650,
                  width: 900,
                  alignContent: 'center',
                }}
              /> */}
            </Grid>
          </Grid>
        )}
        {selectedRoomID !== null ? (
          <Grid item xs={2}>
            <Box
              style={{
                padding: 10,
                backgroundColor: '#e1f5fe',
                maxHeight: window.screen.height - 400,
                minHeight: 670,
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
              }}>
              <Grid container>
                <Grid item xs={12}>
                  <h1 style={{textAlign: 'center'}}>Contact Information</h1>
                </Grid>
                <Grid item xs={12}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      width: '100%',
                    }}>
                    <Avatar
                      sx={{bgcolor: 'gray', width: 100, height: 100}}
                      alt={conversations.name}
                      src="/broken-image.jpg"
                    />
                  </div>
                  <div style={{textAlign: 'center', padding: 20}}>
                    <Typography variant="h5">{conversations.name}</Typography>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <Accordion
                    disabled
                    style={{backgroundColor: '#e1f5fe', boxShadow: 'none'}}
                    expanded={expanded === 'media'}
                    onChange={handleChange('media')}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header">
                      <Grid
                        container
                        justifyContent={'center'}
                        alignItems={'center'}
                        textAlign={'left'}>
                        <Grid item xs={12} style={{width: '100%'}}>
                          <Typography variant="h5">Media</Typography>
                        </Grid>
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>Not available.</Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Divider />
                  <Accordion
                    disabled
                    style={{backgroundColor: '#e1f5fe', boxShadow: 'none'}}
                    expanded={expanded === 'files'}
                    onChange={handleChange('files')}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header">
                      <Grid
                        container
                        justifyContent={'center'}
                        alignItems={'center'}
                        textAlign={'left'}>
                        <Grid item xs={12} style={{width: '100%'}}>
                          <Typography variant="h5">Files</Typography>
                        </Grid>
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>Not available.</Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Divider />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        ) : (
          <Grid item xs={2}>
            <Box
              style={{
                padding: 10,
                backgroundColor: '#e1f5fe',
                maxHeight: window.screen.height - 400,
                minHeight: 670,
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
              }}>
              <Typography variant="h5">
                No selected room or conversation
              </Typography>
            </Box>
          </Grid>
        )}
      </Grid>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Edit contact</DialogTitle>
        <DialogContent>Edit me beybi</DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained" color="error">
            Cancel
          </Button>
          <Button variant="contained">Save</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openConvoDialog}
        onClose={handleClose}
        fullWidth={true}
        maxWidth="sm">
        <DialogTitle>Start a conversation!</DialogTitle>
        <DialogContent>
          <DialogContentText>Recipient</DialogContentText>
          <Autocomplete
            id="combo-box-demo"
            value={selectedContact}
            options={contacts}
            isOptionEqualToValue={(option, value) =>
              option.name === value.name || ''
            }
            onChange={(event, value) => setSelectedContact(value)}
            getOptionLabel={option => option.name || ' '}
            sx={{width: 300, marginTop: 1}}
            renderInput={params => (
              <TextField {...params} label="Search contacts" />
            )}
          />
          <TextField
            id="input-with-icon-textfield"
            label="Message"
            value={message}
            onChange={e => setMessage(e.target.value)}
            inputProps={{maxLength: 250}}
            style={{width: '100%', marginTop: 20}}
            variant="outlined"
            multiline
            rows={4}
            helperText={`${characterLimit} characters left.`}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="error" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            endIcon={<SendIcon />}
            onClick={handleSend}>
            Send
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default Communication;
