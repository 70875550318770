import React, {Fragment, useState, useEffect} from 'react';
import {
  Grid,
  Typography,
  Button,
  Box,
  TextField,
  IconButton,
  Link,
  Backdrop,
  CircularProgress,
} from '@mui/material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CloseIcon from '@mui/icons-material/Close';
import PromptModal from '../lipata/modals/PromptModal';
import {CBEWSL_SITE} from '../host';
import {forgotPassword, verifyOTP} from '../lipata/api/login_api';

function ForgotPassword(props) {
  const {setOpenForgotPassword} = props;

  const handleCloseSignUp = () => {
    setOpenForgotPassword(false);
  };

  //Input credentials
  const [credentials, setCredentials] = useState(true);
  const [indicator, setIndicator] = useState('');
  const [submitButtonState, setSubmitButtonState] = useState(true);
  const [errorIndicator, setErrorIndicator] = useState(null);
  const [passwordRequest, setPasswordRequest] = useState(null);

  useEffect(() => {
    if (indicator === '') {
      setSubmitButtonState(true);
    } else {
      setSubmitButtonState(false);
    }
  }, [indicator, submitButtonState]);

  const handleSubmitCredentials = () => {
    const input = {
      indicator,
    };
    forgotPassword(input, data => {
      const {status, message} = data;
      if (status === true) {
        setCredentials(false);
        setPasswordRequest(message);
      } else {
        setErrorIndicator(message);
      }
    });
  };

  //OTP/New password
  const [OTP, setOTP] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [sendButtonState, setSendButtonState] = useState(true);
  const [notif_message, setNotifMessage] = useState('');
  const [is_open_prompt_modal, setIsOpenPromptModal] = useState(false);
  const [passwordCreation, setPasswordCreation] = useState(null);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const success =
    'Password reset successful! You may now close this window and log-in with your username and new password';
  const warningMessage = 'Passwords do not match';

  const handleValidation = messsage => {
    setNotifMessage(messsage);
    setIsOpenPromptModal(true);
  };

  const resetValues = () => {
    setOTP('');
    setNewPassword('');
    setConfirmNewPassword('');
  };

  const handleSend = () => {
    setOpenBackdrop(!openBackdrop);
    const input = {
      otp: OTP,
      password: newPassword,
    };
    verifyOTP(input, data => {
      const {status, message} = data;
      handleValidation(message);
      if (status === true) {
        resetValues();
        setPasswordCreation(success);
        setOpenBackdrop(false);
      }
    });
  };

  useEffect(() => {
    if (OTP === '' || newPassword !== confirmNewPassword) {
      setSendButtonState(true);
    } else {
      setSendButtonState(false);
    }
    return;
  }, [sendButtonState, OTP, newPassword, confirmNewPassword]);

  return (
    <Fragment>
      {credentials ? (
        <Grid container sx={{padding: 8}}>
          <Grid
            container
            direction={'row'}
            justifyContent={'flex-end'}
            alignItems={'flex-start'}>
            <IconButton onClick={handleCloseSignUp}>
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            <Box elevato="true">
              <Typography variant="h4" sx={{marginBottom: 4}}>
                Forgot Password
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Card sx={{minWidth: 275}}>
              <CardContent>
                <Grid container spacing={2} marginBottom={2}>
                  <Grid item xs={12}>
                    <TextField
                      label="Username/Mobile number"
                      variant="outlined"
                      style={{width: '100%'}}
                      value={indicator}
                      onChange={e => setIndicator(e.target.value)}
                      helperText="Please enter your registered details. The system will send an OTP code to your mobile number for password reset"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography color={'red'}>{errorIndicator}</Typography>
                  </Grid>
                </Grid>
              </CardContent>
              <CardActions sx={{justifyContent: 'flex-end'}}>
                <Button
                  variant="contained"
                  color="error"
                  onClick={handleCloseSignUp}>
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={handleSubmitCredentials}
                  disabled={submitButtonState}>
                  Send OTP code
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      ) : (
        <Fragment>
          <Grid container sx={{padding: 8}}>
            <Grid
              container
              direction={'row'}
              justifyContent={'flex-end'}
              alignItems={'flex-start'}>
              <IconButton onClick={handleCloseSignUp}>
                <CloseIcon />
              </IconButton>
            </Grid>
            <Grid item xs={12}>
              <Box elevato="true">
                <Typography variant="h4" sx={{marginBottom: 2}}>
                  Forgot Password
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2" sx={{marginBottom: 2}}>
                {passwordRequest}. Please request OTP.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Card sx={{minWidth: 275}}>
                <CardContent>
                  <Grid container spacing={2} marginBottom={2}>
                    <Grid item xs={12}>
                      <TextField
                        label="OTP"
                        variant="outlined"
                        style={{width: '100%'}}
                        value={OTP}
                        onChange={e => setOTP(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        label="New Password"
                        variant="outlined"
                        style={{width: '100%'}}
                        value={newPassword}
                        onChange={e => setNewPassword(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        label="Confirm new password"
                        variant="outlined"
                        style={{width: '100%'}}
                        value={confirmNewPassword}
                        onChange={e => setConfirmNewPassword(e.target.value)}
                      />
                      {newPassword !== confirmNewPassword && (
                        <Typography color={'red'}>{warningMessage}</Typography>
                      )}
                    </Grid>
                    {/* <Grid item xs={12}>
                      <Link component="button" variant="body2" disabled>
                        Didn't receive OTP code? Click here to resend OTP
                      </Link>
                    </Grid> */}
                    <Typography variant="overline" display="block" gutterBottom>
                      {passwordCreation}
                    </Typography>
                  </Grid>
                </CardContent>
                <CardActions sx={{justifyContent: 'flex-end'}}>
                  <Button
                    variant="contained"
                    onClick={() => setCredentials(true)}
                    size="small"
                    color="error">
                    Cancel
                  </Button>
                  <Button
                    size="small"
                    variant="contained"
                    disabled={sendButtonState}
                    onClick={handleSend}>
                    Reset Password
                  </Button>
                </CardActions>
              </Card>
            </Grid>
            <PromptModal
              isOpen={is_open_prompt_modal}
              setOpenModal={setIsOpenPromptModal}
              handleValidation={handleValidation}
              notifMessage={notif_message}
            />
            <Grid>
              <Backdrop
                sx={{color: '#fff', zIndex: theme => theme.zIndex.drawer + 1}}
                open={openBackdrop}>
                <CircularProgress color="inherit" />
              </Backdrop>
            </Grid>
          </Grid>
        </Fragment>
      )}
    </Fragment>
  );
}

export default ForgotPassword;
