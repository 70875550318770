import axios from 'axios';

const API_URL = `${process.env.REACT_APP_API_URL}`;
export function getAllProfiles(callback) {
  const api_link = `${API_URL}/api/risk_profile/get_all`;
  axios
    .get(api_link)
    .then(response => {
      const {data} = response;
      console.log(`Events`, data);
      callback(data);
    })
    .catch(error => {
      console.error(error);
    });
}

export function getMembers(id, callback) {
  const api_link = `${API_URL}/api/risk_profile/view/${id}`;
  axios
    .get(api_link)
    .then(response => {
      const {data} = response;
      console.log(`Events`, data);
      callback(data);
    })
    .catch(error => {
      console.error(error);
    });
}

export function saveRiskProfile(input, callback) {
  const api_link = `${API_URL}/api/risk_profile/add`;
  axios
    .post(api_link, input)
    .then(response => {
      const {data} = response;
      console.log('Save Risk Profile', data);
      callback(data);
    })
    .catch(error => {
      console.error(error);
    });
}

export function deleteRiskProfile(input, callback) {
  const api_link = `${API_URL}/api/risk_profile/delete`;
  axios
    .post(api_link, input)
    .then(response => {
      const {data} = response;
      console.log('Delete Risk Profile', data);
      callback(data);
    })
    .catch(error => {
      console.error(error);
    });
}

export function updateRiskProfile(input, callback) {
  const api_link = `${API_URL}/api/risk_profile/update`;
  axios
    .post(api_link, input)
    .then(response => {
      const {data} = response;
      console.log('Update Risk Profile', data);
      callback(data);
    })
    .catch(error => {
      console.error(error);
    });
}
