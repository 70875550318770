import axios from "axios";
const API_URL = `${process.env.REACT_APP_API_URL}`;


export const getFilesFromFolder = (folder, callback) => {
    const api_link = `${API_URL}/api/misc/get_files/${folder}`;
    axios
      .get(api_link)
      .then((response) => {
        if (response.data.status === true) {
          callback(response.data.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };


  export const uploadResources = (input, callback) => {
    const api_link = `${API_URL}/api/upload/save_resources`;
    axios
      .post(api_link, input)
      .then((response) => {
        const { data } = response;
        callback(data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  export const deleteFile = (folder, filename, callback) => {
    const api_link = `${API_URL}/api/delete_file`;
    axios
      .post(api_link, { folder: folder, filename: filename })
      .then((response) => {
        callback();
      })
      .catch((error) => {
        console.error(error);
      });
  };