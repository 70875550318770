import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Typography,
  TextField,
  Backdrop,
  CircularProgress,
} from '@mui/material';
import React, {Fragment, useState, useEffect} from 'react';
import moment from 'moment';
import {updateAlertStatus} from '../api/opcen';

function ValidationModal(props) {
  const {
    isOpen,
    trigger,
    setOpenModal,
    handleValidation,
    triggers,
    setIsValidated,
    alertTrigger,
    capitalizeFirstLetter,
    generateDashboardData,
    setAlertVariant,
  } = props;
  const [remarks, setRemarks] = useState('');
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const invalidAlert = () => {
    setOpenBackdrop(!openBackdrop);
    setOpenModal(false);
    const input = {
      remarks,
      alert_status: -1,
      trigger_id: alertTrigger.trigger_id,
      user_id: 19,
      trigger_ts: `${moment(alertTrigger.ts_updated).format(
        'YYYY-MM-DD HH:MM:00',
      )}`,
    };
    console.log(input);
    updateAlertStatus(input, data => {
      if (data === 'success') {
        setAlertVariant('success');
      } else {
        setAlertVariant('error');
      }
      setOpenModal(false);
      generateDashboardData();
      console.log(data);
      handleValidation('Alert invalidated');
      setRemarks('');
      setIsValidated(true);
      setOpenBackdrop(false);
    });
  };
  const validatingAlert = () => {
    setOpenBackdrop(!openBackdrop);
    setOpenModal(false);
    const input = {
      remarks,
      alert_status: 0,
      trigger_id: alertTrigger.trigger_id,
      user_id: 19,
      trigger_ts: `${moment(alertTrigger.ts_updated).format(
        'YYYY-MM-DD HH:MM:00',
      )}`,
    };
    console.log(input);
    updateAlertStatus(input, data => {
      setOpenModal(false);
      generateDashboardData();
      console.log(data);
      handleValidation('Validating alert');
      setRemarks('');
      setIsValidated(true);
      setOpenBackdrop(false);
    });
  };

  const validAlert = () => {
    setOpenBackdrop(!openBackdrop);
    const input = {
      remarks,
      alert_status: 1,
      trigger_id: alertTrigger.trigger_id,
      user_id: 19,
      trigger_ts: `${moment(alertTrigger.ts_updated).format(
        'YYYY-MM-DD HH:MM:00',
      )}`,
    };
    console.log(input);
    updateAlertStatus(input, data => {
      setOpenModal(false);
      generateDashboardData();
      console.log(data);
      handleValidation('Alert validation successful');
      setRemarks('');
      setIsValidated(true);
      setOpenBackdrop(false);
    });
  };

  return (
    <Dialog
      fullWidth
      fullScreen={false}
      open={isOpen}
      aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Validate Alert</DialogTitle>
      <DialogContent>
        <Typography variant="body1" style={{marginBottom: 10}}>
          {capitalizeFirstLetter(
            alertTrigger.trigger_type ? alertTrigger.trigger_type : '-',
          )}{' '}
          - Alert level {trigger.alert_level}
        </Typography>
        <Typography variant="subtitle2">{alertTrigger.tech_info}</Typography>
        <TextField
          id="standard-basic"
          label="Remarks"
          fullWidth
          style={{marginTop: 10}}
          onChange={e => setRemarks(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={validatingAlert} color="primary">
          Validating
        </Button>
        <Button variant="contained" onClick={invalidAlert} color="error">
          Invalid
        </Button>
        <Button
          variant="contained"
          onClick={() => validAlert('Alert validation success!')}
          color="success">
          Valid
        </Button>
      </DialogActions>
      <Backdrop
        sx={{color: '#fff', zIndex: theme => theme.zIndex.drawer + 1}}
        open={openBackdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Dialog>
  );
}

export default ValidationModal;
