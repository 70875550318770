import React, {Fragment} from 'react';
import {useParams} from 'react-router-dom';
import {Grid, Typography, Box} from '@mui/material';
import SubsurfaceGraph from '../analysis/SubsurfaceGraph';

const Subsurface = () => {
  const {site_code} = useParams();
  return (
    <Fragment>
      <Grid item xs={12} sx={{padding: 8}}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Box>
            <Typography variant="h4" sx={{marginBottom: 4}}>
              {site_code === 'lpa'
                ? 'Brgy. Lipata'
                : 'Sitio Campo Uno, Brgy. Tenani'}{' '}
              Subsurface Data
            </Typography>
          </Box>
        </Grid>
        <SubsurfaceGraph site_code={site_code} />
      </Grid>
    </Fragment>
  );
};

export default Subsurface;
