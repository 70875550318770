import axios from 'axios';
const API_URL = `${process.env.REACT_APP_API_URL}`;

export function saveFeedback(input, callback) {
  const api_link = `${API_URL}/api/bug_report/add`;
  axios
    .post(api_link, input)
    .then(response => {
      const {data} = response;
      console.log('Save Feedback', data);
      callback(data);
    })
    .catch(error => {
      console.error(error);
    });
}
