import React from 'react';
import MUIDataTable from 'mui-datatables';
import {IconButton} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const MuiTable = props => {
  // cmd: cud, cu, c, cd
  const {data, options, onView, onEdit, onDelete} = props;
  const {columns, rows} = data;
  let temp = [];

  let tableColumns = [...columns];
  rows.forEach(element => {
    temp.push({
      ...element,
      actions: [
        <div>
          {onEdit && (
            <IconButton
              onClick={() => {
                onEdit(element);
              }}
              arial-label="edit"
              component="span">
              <EditIcon />
            </IconButton>
          )}

          {onDelete && (
            <IconButton
              onClick={() => {
                onDelete(element);
              }}
              arial-label="delete"
              component="span">
              <DeleteIcon />
            </IconButton>
          )}

          {onView && (
            <IconButton
              onClick={() => {
                onView(element);
              }}
              arial-label="view"
              component="span">
              <VisibilityIcon />
            </IconButton>
          )}
        </div>,
      ],
    });
  });

  return (
    <MUIDataTable
      title={''}
      data={temp}
      columns={tableColumns}
      options={options}
    />
  );
};

export default MuiTable;
