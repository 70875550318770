import React, {Fragment, useState, useEffect} from 'react';
import {
    Grid, Typography, Card, List, ListItem, Box
  } from '@mui/material';

const TermsOfUse = () => {
    return(
        <Grid container justifyContent={'center'} sx={{marginTop: 2}}>
            <Card style={{width: '80%', padding: 20}}>
                <Typography variant='h4'><b>Welcome to CBEWS-L Lipata, Paranas</b></Typography>
                <br />
                <Typography variant='body1'>These terms of service outline the rules and regulations for the use of CBEWS-L Lipata, Paranas' Website.</Typography>
                <br />
                <Typography variant='body1'>By accessing this website we assume you accept these terms of service in full. Do not continue to use CBEWS-L Lipata, Paranas' website if you do not accept all of the terms of service stated on this page.</Typography>
                <br />
                <Typography variant='body1'>The following terminology applies to these Terms of Service, Privacy Statement and Disclaimer Notice, and any or all Agreements: "Client", "You" and "Your" refers to you, the person accessing this website and accepting the Company's terms of service. "The Company", "Ourselves", "We", "Our" and "Us", refers to our Company. "Party", "Parties", or "Us", refers to both the Client and ourselves, or either the Client or ourselves. All terms refer to the offer, acceptance, and consideration of payment necessary to undertake the process of our assistance to the Client in the most appropriate manner, whether by formal meetings of a fixed duration or any other means, for the express purpose of meeting the Client's needs in respect of the provision of the Company's stated services/products, in accordance with and subject to, prevailing law of. Any use of the above terminology or other words in the singular, plural, capitalization and/or he/she or they, are taken as interchangeable and therefore as referring to the same.</Typography>
                <br />
                <Typography variant='h5'><b>License</b></Typography>
                <br />
                <Typography variant='body1'>Unless otherwise stated, CBEWS-L Lipata, Paranas and/or its licensors own the intellectual property rights for all material on CBEWS-L Lipata, Paranas. All intellectual property rights are reserved. You may view and/or print pages from paranasdynaslope.com for your personal use subject to restrictions set in these terms of service.</Typography>
                <br />
                <Typography variant='body1'>You must not:</Typography>
                <List>
                    <ListItem>
                        <Typography variant='body1'>Republish material from paranasdynaslope.com</Typography>
                    </ListItem>
                    <ListItem>
                        <Typography variant='body1'>Sell, rent, or sub-license material from paranasdynaslope.com</Typography>
                    </ListItem>
                    <ListItem>
                        <Typography variant='body1'>Redistribute content from CBEWS-L Lipata, Paranas (unless content is specifically made for redistribution).</Typography>
                    </ListItem>
                </List>
                <Typography variant='h5'><b>Disclaimer</b></Typography>
                <br />
                <Typography variant='body1'>To the maximum extent permitted by applicable law, we exclude all representations, warranties, and conditions relating to our website and the use of this website (including, without limitation, any warranties implied by law in respect of satisfactory quality, fitness for purpose and/or the use of reasonable care and skill). Nothing in this disclaimer will:</Typography>
                <List>
                    <ListItem>
                        <Typography>limit or exclude our or your liability for death or personal injury resulting from negligence;</Typography>
                    </ListItem>
                    <ListItem>
                        <Typography>limit or exclude our or your liability for fraud or fraudulent misrepresentation;</Typography>
                    </ListItem>
                    <ListItem>
                        <Typography>limit any of our or your liabilities in any way that is not permitted under applicable law; or</Typography>
                    </ListItem>
                    <ListItem>
                        <Typography>exclude any of our or your liabilities that may not be excluded under applicable law.</Typography>
                    </ListItem>
                </List>
                <Typography>The limitations and exclusions of liability set out in this Section and elsewhere in this disclaimer: (a) are subject to the preceding paragraph; and (b) govern all liabilities arising under the disclaimer or in relation to the subject matter of this disclaimer, including liabilities arising in contract, in tort (including negligence) and for breach of statutory duty.</Typography>
                <br />
                <Typography>To the extent that the website and the information and services on the website are provided free of charge, we will not be liable for any loss or damage of any nature.</Typography>
                <br />
                <Typography variant='h5'><b>Credit & Contact Information</b></Typography>
                <br />
                <Typography>This Terms of service page was created at privacyterms.io terms & conditions generator. If you have any queries regarding any of our terms, please contact us.</Typography>
                
            </Card>
        </Grid>
    )
}

export default TermsOfUse;