import React, {Fragment, useState, useEffect} from 'react';
import {Grid, Typography, Tab, Tabs, AppBar, Toolbar, Tooltip} from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {useNavigate} from 'react-router-dom';
import moment from 'moment';

import HazardMap from '../../assets/lipata_hazard_map2.jpg';

const MainMenu = () => {
  let navigate = useNavigate();
  const [server_time, setServerTime] = useState('');
  const [value, setValue] = useState(0);

  const [anchorElCRA, setAnchorElCRA] = React.useState(null);
  const [anchorElAnalysis, setAnchorElAnalysis] = React.useState(null);
  const [anchorElGroundData, setAnchorElGroundData] = React.useState(null);
  const openCRA = Boolean(anchorElCRA);
  const openAnalysis = Boolean(anchorElAnalysis);
  const openGroundData = Boolean(anchorElGroundData);

  const [selectedSite, setSelectedSite] = useState('lpa');
  const [anchorSubMenu, setAnchorSubMenu] = useState(null);
  const [openCRASub, setOpenCRASub] = useState(false);
  const [openAnalysisSub, setOpenAnalysisSub] = useState(false);
  const [openGroundSub, setOpenGroundSub] = useState(false);

  const a11yProps = index => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  };

  const handleOpen = () => {
    window.open(HazardMap, '_blank');
  };

  const preventDefault = event => {
    event.preventDefault();
  };

  const handleClickCRA = event => {
    setAnchorElCRA(event.currentTarget);
  };

  const handleClickAnalysis = event => {
    setAnchorElAnalysis(event.currentTarget);
  };

  const handleClickGroundData = event => {
    setAnchorElGroundData(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorElCRA(null);
    setAnchorElAnalysis(null);
    setAnchorElGroundData(null);
    handleCloseSubs();
  };

  const handleCloseSubs = () => {
    setOpenCRASub(false);
    setOpenGroundSub(false);
    setOpenAnalysisSub(false);
  };

  const handleClickSubMenu = (e, value, site) => {
    setSelectedSite(site);
    setAnchorSubMenu(e.currentTarget);
    switch (value) {
      case 'cra':
        setOpenCRASub(true);
        break;
      case 'ground':
        setOpenGroundSub(true);
        break;
      case 'analysis':
        setOpenAnalysisSub(true);
        break;
      default:
        handleClose();
        break;
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    switch (newValue) {
      case 0:
        navigate('/opcen');
        break;
      case 1:
        event.preventDefault();
        break;
      case 2:
        event.preventDefault();
        break;
      case 3:
        navigate('/communication');
        break;
      case 4:
        navigate('/events');
        break;
      case 5:
        event.preventDefault();
        break;
      default:
        navigate('/opcen');
        break;
    }
  };

  const handleCurrentTab = () => {
    const path_name = window.location.pathname;
    if (path_name === '/opcen') {
      setValue(0);
    } else if (
      path_name.includes(`/surficial_markers`) ||
      path_name.includes(`/moms`)
    
    ) {
      setValue(2);
    } else if (
      path_name.includes(`/rainfall`) ||
      path_name.includes(`/surficial`) ||
      path_name.includes(`/subsurface`) ||
      path_name.includes(`/earthquake`) ||
      path_name.includes(`/analysis`)
    ) {
      setValue(1);
    } else if (path_name === '/communication') {
      setValue(3);
    } else if (path_name === '/events') {
      setValue(4);
    } else if (path_name.includes('/assessment')) {
      setValue(5);
    } else {
      setValue(6);
    }
  };

  useEffect(() => {
    handleCurrentTab();
  });

  useEffect(() => {
    const interval = setInterval(() => {
      let dt = moment().format('ddd DD-MMM-YYYY hh:mm:ss a');
      setServerTime(dt);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Fragment>
      <Grid
        item
        xs={12}
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          width: '100%',
        }}>
        <AppBar position="static" color="inherit">
          <Grid container>
            <Grid item md={10}>
              <Toolbar style={{justifyContent: 'center'}}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example">
                  <Tab label="OPCEN" {...a11yProps(0)} />
                  
                  <Tab
                    label="ANALYSIS"
                    onClick={e => {
                      handleClickAnalysis(e);
                      preventDefault(e);
                    }}
                    {...a11yProps(1)}
                  />

                  <Tab
                    label="GROUND DATA"
                    onClick={e => {
                      handleClickGroundData(e);
                      preventDefault(e);
                    }}
                    {...a11yProps(2)}
                  />

                  <Tab 
                    style={{ pointerEvents: "auto" }}
                    disabled 
                    {...a11yProps(3)}
                    icon={
                      <Tooltip title="Coming Soon!">
                          <span>COMMUNICATION</span>
                      </Tooltip>
                    }
                  >
                  </Tab>

                  <Tab label="EVENTS" {...a11yProps(4)} />
                  <Tab
                    label="ASSESSMENT"
                    onClick={e => {
                      handleClickCRA(e);
                      preventDefault(e);
                    }}
                    {...a11yProps(5)}
                  />
                  
                  <Tab style={{display: 'none'}} {...a11yProps(6)} />
                </Tabs>
              </Toolbar>
            </Grid>
            <Grid item md={2} style={{alignSelf: 'center'}}>
              <Typography variant="body1">
                {server_time.toUpperCase()}
              </Typography>
            </Grid>
          </Grid>
        </AppBar>
      </Grid>

      <Grid item xs={12}>
        <Menu
          id="menu"
          anchorEl={anchorElAnalysis}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 10,
          }}
          open={openAnalysis}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'button',
            // onMouseLeave: handleClose,
          }}>
          <MenuItem
            onClick={e => {
              handleClickSubMenu(e, 'analysis', 'lpa');
            }}>
            LPA
            <ArrowRightIcon />
          </MenuItem>
          <MenuItem
            onClick={e => {
              handleClickSubMenu(e, 'analysis', 'scu');
            }}>
            SCU
            <ArrowRightIcon />
          </MenuItem>
        </Menu>

        <Menu
          id="menu"
          anchorEl={anchorElGroundData}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 25,
          }}
          open={openGroundData}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'button',
            // onMouseLeave: handleClose,
          }}>
          <MenuItem
            onClick={e => {
              handleClickSubMenu(e, 'ground', 'lpa');
            }}>
            LPA
            <ArrowRightIcon />
          </MenuItem>
          <MenuItem
            onClick={e => {
              handleClickSubMenu(e, 'ground', 'scu');
            }}>
            SCU
            <ArrowRightIcon />
          </MenuItem>
        </Menu>

        <Menu
          id="menu"
          anchorEl={anchorElCRA}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 20,
          }}
          open={openCRA}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'button',
          }}>
          <MenuItem
            onClick={e => {
              handleClickSubMenu(e, 'cra', 'lpa');
            }}>
            LPA
            <ArrowRightIcon />
          </MenuItem>
          <MenuItem
            onClick={e => {
              handleClickSubMenu(e, 'cra', 'scu');
            }}>
            SCU
            <ArrowRightIcon />
          </MenuItem>
        </Menu>

        {/* SUB MENUUUUUUS */}
        <Menu
          anchorEl={anchorSubMenu}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={openCRASub}
          onClose={handleCloseSubs}
          MenuListProps={{
            'aria-labelledby': 'button',
            onMouseLeave: handleCloseSubs,
          }}>
          {selectedSite === 'lpa' && (
            <MenuItem
              onClick={() => {
                handleOpen();
                handleClose();
              }}>
              Hazard Map
            </MenuItem>
          )}
          <MenuItem
            onClick={() => {
              navigate(`/assessment/${selectedSite}`);
              handleClose();
            }}>
            Household Data
          </MenuItem>
        </Menu>

        <Menu
          anchorEl={anchorSubMenu}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={openGroundSub}
          onClose={handleCloseSubs}
          MenuListProps={{
            'aria-labelledby': 'button',
            onMouseLeave: handleCloseSubs,
          }}>
          <MenuItem
            onClick={() => {
              navigate(`/surficial_markers/${selectedSite}`);
              handleClose();
            }}>
            Surficial Markers
          </MenuItem>
          <MenuItem
            onClick={() => {
              navigate(`/moms/${selectedSite}`);
              handleClose();
            }}>
            Landslide Features
          </MenuItem>
        </Menu>

        <Menu
          anchorEl={anchorSubMenu}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={openAnalysisSub}
          onClose={handleCloseSubs}
          MenuListProps={{
            'aria-labelledby': 'button',
            onMouseLeave: handleCloseSubs,
          }}>
          {selectedSite === 'lpa' && (
            <MenuItem
              onClick={() => {
                navigate(`/rainfall/${selectedSite}`);
                handleClose();
              }}>
              Rainfall Data
            </MenuItem>
          )}
          <MenuItem
            onClick={() => {
              navigate(`/surficial/${selectedSite}`);
              handleClose();
            }}>
            Surficial Data
          </MenuItem>
          {selectedSite === 'lpa' && (
            <MenuItem
              onClick={() => {
                navigate(`/subsurface/${selectedSite}`);
                handleClose();
              }}>
              Subsurface Data
            </MenuItem>
          )}
          <MenuItem
            onClick={() => {
              navigate(`/earthquake/${selectedSite}`);
              handleClose();
            }}>
            Earthquake Data
          </MenuItem>
          <MenuItem
            onClick={() => {
              navigate(`/analysis/${selectedSite}`);
              handleClose();
            }}>
            All Charts
          </MenuItem>
        </Menu>
      </Grid>
    </Fragment>
  );
};

export default MainMenu;
