import axios from 'axios';
// import {host} from '../../host';
const API_URL = `${process.env.REACT_APP_API_URL}`;

export function loginAccount(input, callback) {
  const api_link = `${API_URL}/api/signin`;
  axios
    .post(api_link, input)
    .then(response => {
      const {data} = response;
      console.log('Login:', data);
      callback(data);
    })
    .catch(error => {
      console.error(error);
    });
}

export function validateCredentials(input, callback) {
  const api_link = `${API_URL}/api/validate_username`;
  axios
    .post(api_link, input)
    .then(response => {
      const {data} = response;
      console.log('Username validation:', data);
      callback(data);
    })
    .catch(error => {
      console.error(error);
    });
}

export function createAccount(input, callback) {
  const api_link = `${API_URL}/api/signup`;
  axios
    .post(api_link, input)
    .then(response => {
      const {data} = response;
      console.log('Login:', data);
      callback(data);
    })
    .catch(error => {
      console.error(error);
    });
}

export function forgotPassword(input, callback) {
  const api_link = `${API_URL}/api/forgot_password`;
  axios
    .post(api_link, input)
    .then(response => {
      const {data} = response;
      console.log('Login:', data);
      callback(data);
    })
    .catch(error => {
      console.error(error);
    });
}

export function verifyOTP(input, callback) {
  const api_link = `${API_URL}/api/verify_otp`;
  axios
    .post(api_link, input)
    .then(response => {
      const {data} = response;
      console.log('Login:', data);
      callback(data);
    })
    .catch(error => {
      console.error(error);
    });
}

export const VerifyOldPassword = (password, user_id, callback) => {
  const api_link = `${API_URL}/api/users/verify_old_password`;
  axios
    .post(api_link, {old_pass: password, user_id})
    .then(response => {
      callback(response.data);
    })
    .catch(error => {});
};

export const UpdatePassword = (user_id, new_pass, callback) => {
  const api_link = `${API_URL}/api/users/change_password`;
  axios
    .post(api_link, {new_pass, user_id})
    .then(response => {
      callback(response.data);
    })
    .catch(error => {});
};
