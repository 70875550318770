import React, {Fragment, useState, useEffect} from 'react';
import {
  Grid,
  IconButton,
  Typography,
  Tooltip,
  Avatar,
  Dialog,
  Popover,
  Card,
  CardContent,
  Badge,
} from '@mui/material';
import HeaderBanner from '../../assets/paranas_banner.png';
import MenuIcon from '@mui/icons-material/Menu';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import CloseIcon from '@mui/icons-material/Close';
import {useNavigate} from 'react-router-dom';
import moment from 'moment';
import Feedback from '../lipata/Feedback';
import MainMenu from './MainMenu';
import male_icon from '../../assets/male_icon.png';
import female_icon from '../../assets/female_icon.png';
import {getNotifications, readNotifications} from '../lipata/api/header_api';

const LipataHeader = props => {
  let navigate = useNavigate();
  const [openFeedback, setOpenFeedback] = useState(false);
  const [notifs, setNotifs] = useState([]);
  const [openNotification, setOpenNotification] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [notifColor, setNotifColor] = useState(false);
  const [color, setColor] = useState('#0492C2');
  const [profileIcon, setProfileIcon] = useState(null);

  const handleOpenFeedback = () => {
    setOpenFeedback(true);
  };

  useEffect(() => {
    const data = localStorage.getItem('data');
    const parse_data = JSON.parse(data);
    const user_sex = parse_data.user.sex;

    if (parse_data) {
      setCurrentUser(parse_data.profile.user_id);
      // getNotifications(parse_data.profile.user_id, response => {
      //   setNotifs(response.data);
      // });
    }

    if (user_sex === "Male") {
      setProfileIcon(male_icon);
    } else {
      setProfileIcon(female_icon);
    }
  }, []);

  const handleOpenNotifs = () => {
    setOpenNotification(true);
    setNotifColor(true);
    if (notifColor === true) {
      setColor('white');
    }
  };

  const handleCloseNotifs = () => {
    setOpenNotification(false);
  };

  const handleReadNotifs = notification_id => {
    // readNotifications(notification_id, data => {
    //   const {status} = data;
    //   if (status === true) {
    //     getNotifications(currentUser, response => {
    //       setNotifs(response.data);
    //     });
    //   }
    // });
  };

  const [anchorElSettings, setAnchorElSettings] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const openSettings = Boolean(anchorElSettings);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setAnchorElSettings(null);
  };

  const logoutAccount = () => {
    localStorage.removeItem('data');
    window.location = '/';
  };

  return (
    <Fragment>
      <Grid container style={{background: '#e6e6e6'}}>
        <Grid item xs={8} sm={8} md={8} lg={10}>
          <div
            style={{
              textAlign: 'left',
              height: 'auto',
              width: '100%',
              padding: 10,
            }}>
            <img
              src={HeaderBanner}
              alt="lpa_banner"
              style={{
                objectFit: 'contain',
                height: 'auto',
                width: window.innerWidth - 300,
                marginRight: 8,
              }}
            />
          </div>
        </Grid>
        <Grid item xs={4} sm={4} md={4} lg={2}>
          <div style={{textAlign: 'end', padding: 5}}>
            <Tooltip title="Notification">
              <IconButton onClick={handleOpenNotifs} sx={{p: 2, mt: 4}}>
                <Badge
                  badgeContent={notifs.filter(x => x.is_read === false).length}
                  color="error">
                  <NotificationsNoneIcon alt="Notification" />
                </Badge>
              </IconButton>
            </Tooltip>
            <Popover
              id="simple-pop-over"
              open={openNotification}
              anchorEl={notifs}
              onClose={handleCloseNotifs}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}>
              {notifs.map(notif => {
                const {notification_id, is_read} = notif;
                return (
                  is_read === false && (
                    <Card
                      sx={{
                        maxWidth: 400,
                        maxHeight: 'auto',
                        borderBottom: 1,
                        cursor: 'pointer',
                      }}>
                      <div style={{backgroundColor: color}}>
                        <div style={{marginLeft: 350}}>
                          <IconButton
                            onClick={event =>
                              handleReadNotifs(notification_id)
                            }>
                            <CloseIcon />
                          </IconButton>
                        </div>
                        <CardContent>{notif.message}</CardContent>
                        <div style={{marginLeft: 250}}>
                          <Typography fontSize={14} color="#636363">
                            {moment(notif.ts).format('YYYY-MM-DD hh:mm a')}
                          </Typography>
                        </div>
                      </div>
                    </Card>
                  )
                );
              })}
            </Popover>
            <Tooltip title="Open settings">
              <IconButton
                onClick={e => {
                  setAnchorElSettings(e.currentTarget);
                }}
                sx={{p: 2, mt: 4}}>
                <Avatar src={profileIcon} alt="Profile photo" />
              </IconButton>
            </Tooltip>

            <Menu
              id="menu-settings"
              anchorEl={anchorElSettings}
              open={openSettings}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'button',
              }}>
              <MenuItem
                onClick={() => {
                  navigate("/profile-setting");
                  handleClose();
                }}
              >
                Profile Settings
              </MenuItem>
              <MenuItem
                onClick={() => {
                  navigate('/change-password');
                  handleClose();
                }}>
                Change Password
              </MenuItem>
            </Menu>

            <IconButton
              id="button"
              aria-controls={open ? 'menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
              sx={{p: 2, mt: 4}}>
              <MenuIcon alt="Menu" />
            </IconButton>
            <Menu
              id="menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'button',
              }}>
              <MenuItem onClick={() => (window.location = '/resources')}>
                Resources
              </MenuItem>
              <MenuItem onClick={handleOpenFeedback}>Feedback</MenuItem>
              <MenuItem onClick={() => (window.location = '/terms-of-use')}>
                Terms of Use
              </MenuItem>
              <MenuItem onClick={() => (window.location = '/privacy-policy')}>
                Privacy Policy
              </MenuItem>
              <MenuItem
                onClick={() => {
                  logoutAccount();
                }}>
                Logout
              </MenuItem>
            </Menu>
          </div>
          <Dialog open={openFeedback} maxWidth fullWidth>
            <Feedback setOpenFeedback={setOpenFeedback} />
          </Dialog>
        </Grid>
      </Grid>
      <MainMenu />
    </Fragment>
  );
};

export default LipataHeader;
