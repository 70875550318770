import React from 'react';
import {Grid, Typography, Box} from '@mui/material';
import EarthquakeChart from '../analysis/EarthquakeChart';

const Earthquake = () => {
  return (
    <Grid item xs={12} sx={{padding: 8}}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Box>
          <Typography variant="h4" sx={{marginBottom: 4}}>
            Earthquake Data
          </Typography>
        </Box>
      </Grid>
      <EarthquakeChart />
    </Grid>
  );
};

export default Earthquake;
