import axios from 'axios';
import {host} from '../../host';
const API_URL = `${process.env.REACT_APP_API_URL}`;
export function getUsers(callback) {
  const api_link = `${API_URL}/message/get_all_users`;
  axios
    .get(api_link)
    .then(response => {
      const {data} = response;
      console.log(`Users`, data);
      callback(data);
    })
    .catch(error => {
      console.error(error);
    });
}

export function getConversations(room_id, callback) {
  const api_link = `${API_URL}/message/get_conversations/${room_id}`;
  axios
    .get(api_link)
    .then(response => {
      const {data} = response;
      console.log(`Conversations`, data);
      callback(data);
    })
    .catch(error => {
      console.error(error);
    });
}

export function getRooms(creator_id, callback) {
  const api_link = `${API_URL}/message/get_rooms/${creator_id}`;
  axios
    .get(api_link)
    .then(response => {
      const {data} = response;
      console.log(`Room`, data);
      callback(data);
    })
    .catch(error => {
      console.error(error);
    });
}

export function sendMessage(input, callback) {
  const api_link = `${API_URL}/message/send`;
  axios
    .post(api_link, input)
    .then(response => {
      const {data} = response;
      console.log(`Sent message`, data);
      callback(data);
    })
    .catch(error => {
      console.error(error);
    });
}

//   export function saveActivity(input, callback) {
//     const api_link = `${API_URL}/api/events/save_activity`;
//     axios
//       .post(api_link, input)
//       .then(response => {
//         const { data } = response;
//         console.log('Save Activity', data);
//         callback(data);
//       })
//       .catch(error => {
//         console.error(error);
//       });
//   }
