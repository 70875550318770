import React, {useEffect} from 'react';
import {Grid, Typography, Card, List, ListItem, Box} from '@mui/material';

const PrivacyPolicy = () => {
  return (
    <Grid container justifyContent={'center'} sx={{marginTop: 2}}>
      <Card style={{width: '80%', padding: 20}}>
        <Typography variant="h4">
          <b>
            <Typography variant="h4">
              <b>PRIVACY NOTICE</b>
            </Typography>
          </b>
        </Typography>
        <br />
        <Typography variant="subtitle">
          <b>February 22, 2023</b>
        </Typography>
        <br />
        <br />
        <Typography variant="h6">
          This privacy notice for Dynaslope Project ("Company," "we," "us," or
          "our") describes how and why we might collect, store, use, and/or
          share ("process") your information when you use our services
          ("Services"), such as when you:
        </Typography>
        <br />
        <br />
        <Typography variant="h6">
          • Visit our website at http://www.paranasdynaslope.com, or any website
          of ours that links to this privacy notice
        </Typography>
        <br />
        <Typography variant="h6">
          • Download and use our mobile application (CBEWS-L Paranas), or any
          other application of ours that links to this privacy notice
        </Typography>
        <br />
        <Typography variant="h6">
          • Engage with us in other related ways, including any sales,
          marketing, or events
        </Typography>
        <br />
        <br />
        <Typography variant="h6">
          Questions or concerns? Reading this privacy notice will help you
          understand your privacy rights and choices. If you do not agree with
          our policies and practices, please do not use our Services. If you
          still have any questions or concerns, please contact us at
          admin@dynaslope.ph.
        </Typography>
        <br />
        <br />
        <Typography variant="h5" sx={{fontWeight: 'bold'}}>
          SUMMARY OF KEY POINTS
        </Typography>
        <br />
        <br />
        <Typography variant="h6">
          <Typography variant="h6" sx={{fontWeight: 'bold'}}>
            What personal information do we process?
          </Typography>{' '}
          When you visit, use, or navigate our Services, we may process personal
          information depending on how you interact with Dynaslope Project and
          the Services, the choices you make, and the products and features you
          use.
        </Typography>
        <br />
        <br />
        <Typography variant="h6">
          <Typography variant="h6" sx={{fontWeight: 'bold'}}>
            Do we receive any information from third parties?
          </Typography>{' '}
          We do not receive any information from third parties.
        </Typography>
        <br />
        <br />
        <Typography variant="h6">
          <Typography variant="h6" sx={{fontWeight: 'bold'}}>
            How do we process your information?
          </Typography>{' '}
          We process your information to provide, improve, and administer our
          Services, communicate with you, for security and fraud prevention, and
          to comply with law. We may also process your information for other
          purposes with your consent. We process your information only when we
          have a valid legal reason to do so.
        </Typography>
        <br />
        <br />
        <Typography variant="h6">
          <Typography variant="h6" sx={{fontWeight: 'bold'}}>
            In what situations and with which parties do we share personal
            information?
          </Typography>{' '}
          We may share information in specific situations and with specific
          third parties.
        </Typography>
        <br />
        <br />
        <Typography variant="h6">
          <Typography variant="h6" sx={{fontWeight: 'bold'}}>
            How do we keep your information safe?
          </Typography>{' '}
          We have organizational and technical processes and procedures in place
          to protect your personal information. However, no electronic
          transmission over the internet or information storage technology can
          be guaranteed to be 100% secure, so we cannot promise or guarantee
          that hackers, cybercriminals, or other unauthorized third parties will
          not be able to defeat our security and improperly collect, access,
          steal, or modify your information.
        </Typography>
        <br />
        <br />
        <Typography variant="h6">
          <Typography variant="h6" sx={{fontWeight: 'bold'}}>
            What are your rights?
          </Typography>{' '}
          Depending on where you are located geographically, the applicable
          privacy law may mean you have certain rights regarding your personal
          information.
        </Typography>
        <br />
        <br />
        <Typography variant="h6">
          <Typography variant="h6" sx={{fontWeight: 'bold'}}>
            How do you exercise your rights?
          </Typography>{' '}
          The easiest way to exercise your rights is by contacting us. We will
          consider and act upon any request in accordance with applicable data
          protection laws.
        </Typography>
        <br />
        <br />
        <Typography variant="h5" sx={{fontWeight: 'bold'}}>
          TABLE OF CONTENTS
        </Typography>
        <br />
        <br />
        <Typography variant="h6">
          1. WHAT INFORMATION DO WE COLLECT?
          <br />
          2. HOW DO WE PROCESS YOUR INFORMATION?
          <br />
          3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
          <br />
          4. HOW LONG DO WE KEEP YOUR INFORMATION?
          <br />
          5. HOW DO WE KEEP YOUR INFORMATION SAFE?
          <br />
          6. DO WE COLLECT INFORMATION FROM MINORS?
          <br />
          7. WHAT ARE YOUR PRIVACY RIGHTS?
          <br />
          8. CONTROLS FOR DO-NOT-TRACK FEATURES
          <br />
          9. DO WE MAKE UPDATES TO THIS NOTICE?
          <br />
          10. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
          <br />
          11. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
          YOU?
          <br />
        </Typography>
        <br />
        <br />
        <Typography variant="h5" sx={{fontWeight: 'bold'}}>
          1. WHAT INFORMATION DO WE COLLECT?
        </Typography>
        <br />
        <br />
        <Typography variant="h6" sx={{fontWeight: 'bold'}}>
          Personal information you disclose to us
        </Typography>
        <br />
        <br />
        <Typography variant="subtitle">
          <Typography variant="subtitle" sx={{fontWeight: 'bold'}}>
            In Short:
          </Typography>{' '}
          We collect personal information that you provide to us.
        </Typography>
        <br />
        <br />
        <Typography variant="h6">
          We collect personal information that you voluntarily provide to us
          when you register on the Services, express an interest in obtaining
          information about us or our products and Services, when you
          participate in activities on the Services, or otherwise when you
          contact us.
        </Typography>
        <br />
        <br />
        <Typography variant="h6">
          <Typography variant="h6" sx={{fontWeight: 'bold'}}>
            Personal Information Provided by You.
          </Typography>{' '}
          The personal information that we collect depends on the context of
          your interactions with us and the Services, the choices you make, and
          the products and features you use. The personal information we collect
          may include the following:
        </Typography>
        <br />
        <br />
        <Typography variant="h6" sx={{paddingLeft: 5}}>
          • names
          <br />
          • phone numbers
          <br />
          • email addresses
          <br />
          • usernames
          <br />
          • passwords
          <br />
        </Typography>
        <br />
        <br />
        <Typography vairant="h6">
          <Typography variant="h6" sx={{fontWeight: 'bold'}}>
            Sensitive Information.
          </Typography>{' '}
          We do not process sensitive information.
        </Typography>
        <br />
        <br />
        <Typography vairant="h6">
          <Typography variant="h6" sx={{fontWeight: 'bold'}}>
            Application Data.
          </Typography>{' '}
          If you use our application(s), we also may collect the following
          information if you choose to provide us with access or permission:
        </Typography>
        <br />
        <br />
        <Typography variant="h6" sx={{paddingLeft: 5, fontStyle: 'italic'}}>
          • Mobile Device Access. We may request access or permission to certain
          features from your mobile device, including your mobile device's
          camera, contacts, sms messages, storage, and other features. If you
          wish to change our access or permissions, you may do so in your
          device's settings. <br />
          <br />
          • Push Notifications. We may request to send you push notifications
          regarding your account or certain features of the application(s). If
          you wish to opt out from receiving these types of communications, you
          may turn them off in your device's settings. <br />
        </Typography>
        <br />
        <br />
        <Typography variant="h6">
          This information is primarily needed to maintain the security and
          operation of our application(s), for troubleshooting, and for our
          internal analytics and reporting purposes.
        </Typography>
        <br />
        <br />
        <Typography variant="h6">
          All personal information that you provide to us must be true,
          complete, and accurate, and you must notify us of any changes to such
          personal information.
        </Typography>
        <br />
        <br />
        <Typography variant="h5" sx={{fontWeight: 'bold'}}>
          2. HOW DO WE PROCESS YOUR INFORMATION?
        </Typography>
        <br />
        <br />
        <Typography variant="subtitle">
          <Typography variant="subtitle" sx={{fontWeight: 'bold'}}>
            In Short:
          </Typography>{' '}
          We process your information to provide, improve, and administer our
          Services, communicate with you, for security and fraud prevention, and
          to comply with law. We may also process your information for other
          purposes with your consent.
        </Typography>
        <br />
        <br />
        <Typography variant="h6" sx={{fontWeight: 'bold'}}>
          We process your personal information for a variety of reasons,
          depending on how you interact with our Services, including:
        </Typography>
        <br />
        <br />
        <Typography variant="h6" sx={{paddingLeft: 5}}>
          •{' '}
          <b>
            To facilitate account creation and authentication and otherwise
            manage user accounts.
          </b>{' '}
          We may process your information so you can create and log in to your
          account, as well as keep your account in working order. <br />•{' '}
          <b>To deliver and facilitate delivery of services to the user.</b> We
          may process your information to provide you with the requested
          service.
          <br />• <b>To respond to user inquiries/offer support to users.</b> We
          may process your information to respond to your inquiries and solve
          any potential issues you might have with the requested service.
          <br />• <b>To enable user-to-user communications.</b> We may process
          your information if you choose to use any of our offerings that allow
          for communication with another user.
        </Typography>
        <br />
        <br />
        <Typography variant="h5" sx={{fontWeight: 'bold'}}>
          3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
        </Typography>
        <br />
        <br />
        <Typography variant="subtitle">
          <Typography variant="subtitle" sx={{fontWeight: 'bold'}}>
            In Short:
          </Typography>{' '}
          We do not share information to third parties.
        </Typography>
        <br />
        <br />
        <Typography variant="h5" sx={{fontWeight: 'bold'}}>
          4. HOW LONG DO WE KEEP YOUR INFORMATION?
        </Typography>
        <br />
        <br />
        <Typography variant="subtitle">
          <Typography variant="subtitle" sx={{fontWeight: 'bold'}}>
            In Short:
          </Typography>{' '}
          We keep your information for as long as necessary to fulfill the
          purposes outlined in this privacy notice unless otherwise required by
          law.
        </Typography>
        <br />
        <br />
        <Typography variant="h6">
          We will only keep your personal information for as long as it is
          necessary for the purposes set out in this privacy notice, unless a
          longer retention period is required or permitted by law. No purpose in
          this notice will require us keeping your personal information for
          longer than the period of time in which users have an account with us.
          <br />
          <br />
          When we have no ongoing legitimate business need to process your
          personal information, we will either delete or anonymize such
          information, or, if this is not possible (for example, because your
          personal information has been stored in backup archives), then we will
          securely store your personal information and isolate it from any
          further processing until deletion is possible.
        </Typography>
        <br />
        <br />
        <Typography variant="h5" sx={{fontWeight: 'bold'}}>
          5. HOW DO WE KEEP YOUR INFORMATION SAFE?
        </Typography>
        <br />
        <br />
        <Typography variant="subtitle">
          <Typography variant="subtitle" sx={{fontWeight: 'bold'}}>
            In Short:
          </Typography>{' '}
          We aim to protect your personal information through a system of
          organizational and technical security measures.
        </Typography>
        <br />
        <br />
        <Typography variant="h6">
          We have implemented appropriate and reasonable technical and
          organizational security measures designed to protect the security of
          any personal information we process. However, despite our safeguards
          and efforts to secure your information, no electronic transmission
          over the Internet or information storage technology can be guaranteed
          to be 100% secure, so we cannot promise or guarantee that hackers,
          cybercriminals, or other unauthorized third parties will not be able
          to defeat our security and improperly collect, access, steal, or
          modify your information. Although we will do our best to protect your
          personal information, transmission of personal information to and from
          our Services is at your own risk. You should only access the Services
          within a secure environment.
        </Typography>
        <br />
        <br />
        <Typography variant="h5" sx={{fontWeight: 'bold'}}>
          6. DO WE COLLECT INFORMATION FROM MINORS?
        </Typography>
        <br />
        <br />
        <Typography variant="subtitle">
          <Typography variant="subtitle" sx={{fontWeight: 'bold'}}>
            In Short:
          </Typography>{' '}
          We do not knowingly collect data from or market to children under 18
          years of age.
        </Typography>
        <br />
        <br />
        <Typography variant="h6">
          We do not knowingly solicit data from or market to children under 18
          years of age. By using the Services, you represent that you are at
          least 18 or that you are the parent or guardian of such a minor and
          consent to such minor dependent’s use of the Services. If we learn
          that personal information from users less than 18 years of age has
          been collected, we will deactivate the account and take reasonable
          measures to promptly delete such data from our records. If you become
          aware of any data we may have collected from children under age 18,
          please contact us at admin@dynaslope.ph.
        </Typography>
        <br />
        <br />
        <Typography variant="h5" sx={{fontWeight: 'bold'}}>
          7. WHAT ARE YOUR PRIVACY RIGHTS?
        </Typography>
        <br />
        <br />
        <Typography variant="subtitle">
          <Typography variant="subtitle" sx={{fontWeight: 'bold'}}>
            In Short:
          </Typography>{' '}
          You may review, change, or terminate your account at any time.
        </Typography>
        <br />
        <br />
        <Typography variant="h6">
          Withdrawing your consent: If we are relying on your consent to process
          your personal information, which may be express and/or implied consent
          depending on the applicable law, you have the right to withdraw your
          consent at any time. You can withdraw your consent at any time by
          contacting us by using the contact details provided in the section
          "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?" below. <br />
          <br />
          However, please note that this will not affect the lawfulness of the
          processing before its withdrawal nor, when applicable law allows, will
          it affect the processing of your personal information conducted in
          reliance on lawful processing grounds other than consent.
        </Typography>
        <br />
        <br />
        <Typography variant="h6">
          <Typography variant="h6" sx={{fontWeight: 'bold'}}>
            Account Information
          </Typography>{' '}
          If you would at any time like to review or change the information in
          your account or terminate your account, you can:
        </Typography>
        <br />
        <br />
        <Typography variant="h6">
          • Contact us using the contact information provided.
          <br />• Log in to your account settings and update your user account.
        </Typography>
        <br />
        <br />
        <Typography variant="h6">
          Upon your request to terminate your account, we will deactivate or
          delete your account and information from our active databases.
          However, we may retain some information in our files to prevent fraud,
          troubleshoot problems, assist with any investigations, enforce our
          legal terms and/or comply with applicable legal requirements.
        </Typography>
        <br />
        <br />
        <Typography variant="h6">
          If you have questions or comments about your privacy rights, you may
          email us at admin@dynaslope.ph.
        </Typography>
        <br />
        <br />
        <Typography variant="h5" sx={{fontWeight: 'bold'}}>
          8. CONTROLS FOR DO-NOT-TRACK FEATURES
        </Typography>
        <Typography variant="h6">
          Most web browsers and some mobile operating systems and mobile
          applications include a Do-Not-Track ("DNT") feature or setting you can
          activate to signal your privacy preference not to have data about your
          online browsing activities monitored and collected. At this stage, no
          uniform technology standard for recognizing and implementing DNT
          signals has been finalized. As such, we do not currently respond to
          DNT browser signals or any other mechanism that automatically
          communicates your choice not to be tracked online. If a standard for
          online tracking is adopted that we must follow in the future, we will
          inform you about that practice in a revised version of this privacy
          notice.
        </Typography>
        <br />
        <br />
        <Typography variant="h5" sx={{fontWeight: 'bold'}}>
          9. DO WE MAKE UPDATES TO THIS NOTICE?
        </Typography>
        <br />
        <br />
        <Typography variant="subtitle">
          <Typography variant="subtitle" sx={{fontWeight: 'bold'}}>
            In Short:
          </Typography>{' '}
          Yes, we will update this notice as necessary to stay compliant with
          relevant laws.
        </Typography>
        <br />
        <br />
        <Typography variant="h6">
          We may update this privacy notice from time to time. The updated
          version will be indicated by an updated "Revised" date and the updated
          version will be effective as soon as it is accessible. If we make
          material changes to this privacy notice, we may notify you either by
          prominently posting a notice of such changes or by directly sending
          you a notification. We encourage you to review this privacy notice
          frequently to be informed of how we are protecting your information.
        </Typography>
        <br />
        <br />
        <Typography variant="h5" sx={{fontWeight: 'bold'}}>
          10. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
        </Typography>
        <br />
        <br />
        <Typography variant="h6">
          If you have questions or comments about this notice, you may email us
          at admin@dynaslope.ph or by post to: 4th Floor Training Room, PHIVOLCS
          Bldg., C.P Garcia Ave., Brgy. U.P. Campus, Diliman, Quezon City, Metro
          Manila 1101 Philippines
        </Typography>
        <br />
        <br />
        <Typography variant="h5" sx={{fontWeight: 'bold'}}>
          11. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
          YOU?
        </Typography>
        <br />
        <br />
        <Typography variant="h6">
          Based on the applicable laws of your country, you may have the right
          to request access to the personal information we collect from you,
          change that information, or delete it. To request to review, update,
          or delete your personal information, please contact/email us at
          admin@dynaslope.ph or by post to: 4th Floor Training Room, PHIVOLCS
          Bldg., C.P Garcia Ave., Brgy. U.P. Campus, Diliman, Quezon City, Metro
          Manila 1101 Philippines
        </Typography>
      </Card>
    </Grid>
  );
};

export default PrivacyPolicy;
